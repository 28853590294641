/* eslint-disable default-param-last */
import { SERVICES_SUCCESS, SERVICES_FAILURE } from '../actionTypes';

const initialState = {
  serviceData: {},
  isReady: false,
  notification: 'service_list_loading',
};

const servicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SERVICES_SUCCESS:
      return {
        ...state,
        serviceData: action.serviceData,
        isReady: true,
        notification: '',
      };
    case SERVICES_FAILURE:
      return {
        ...state,
        serviceData: {},
        isReady: false,
        notification: action.notification,
      };
    default:
      return state;
  }
};

export default servicesReducer;
