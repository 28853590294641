import React, { useEffect } from 'react';
// import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import managersAPI from '../../../store/actions/managersActions';
import ManagerList from '../ManagerList/ManagerList';
import Button from '../../ReusableElements/Buttons/Button/Button';
import './Management.scss';

export default function Management() {
  const { t, i18n } = useTranslation(['landing_page', 'notifications', 'buttons']);
  const dispatch = useDispatch();
  const {
    managerData,
    isReady,
    notification,
  } = useSelector((state) => state.managers);

  useEffect(() => {
    const header = document.querySelector('.Header');
    const aboutUsAnchor = document.querySelector('#about-us');

    aboutUsAnchor.style.top = `-${header.offsetHeight}px`;

    dispatch(managersAPI());
  }, []);

  return (
    <section className="Management">
      <div id="about-us" className="anchor" />
      <div className="wrapper">
        <div className="management-content">
          <h2 className="heading-two">
            {t('management.heading')}
          </h2>
          <p className="description">
            {t('management.description')}
          </p>
          {
            isReady
              ? <ManagerList managerData={managerData} />
              : (
                <p className="notification">
                  {t(`notifications:${notification}`, notification)}
                </p>
              )
          }
          <Button
            priority="primary"
            link={
              i18n.languages[0] === 'en'
                ? '/about_us/en/'
                : '/about_us/'
            }
          >
            {t('buttons:learn_more')}
          </Button>
        </div>
      </div>
    </section>
  );
}
