import React from 'react';
import { useTranslation } from 'react-i18next';
import CommunityIndicatorList from '../CommunityIndicatorList/CommunityIndicatorList';
import Button from '../../ReusableElements/Buttons/Button/Button';
import './Community.scss';

export default function Community() {
  const { t, i18n } = useTranslation(['landing_page', 'buttons']);

  return (
    <section className="Community">
      <div className="wrapper">
        <div className="community-content">
          <h2 className="heading-two">
            {t('community.heading')}
          </h2>
          <p className="description">
            {t('community.description')}
          </p>
          <CommunityIndicatorList />
          <Button
            priority="primary"
            link={
              i18n.languages[0] === 'en'
                ? '/community/en/'
                : '/community/'
            }
          >
            {t('buttons:learn_more')}
          </Button>
        </div>
      </div>
    </section>
  );
}
