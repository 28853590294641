/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import './CompanyInfo.scss';

export default function CompanyInfo({
  id, name, ceo, activity, codEderpou, email, phone, status,
}) {
  useEffect(() => {}, []);

  return (
    <tr>
      <td>{id}</td>
      <td>{name}</td>
      <td>{ceo}</td>
      <td>{activity}</td>
      <td>{codEderpou}</td>
      <td>{email}</td>
      <td>{phone}</td>
      <td>{status}</td>
    </tr>
  );
}
