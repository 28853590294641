/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import Button from '../../ReusableElements/Buttons/Button/Button';
import firstSlide from '../../../assets/images/conference-halls/01_slide.png';
import secondSlide from '../../../assets/images/conference-halls/02_slide.png';
import thirdSlide from '../../../assets/images/conference-halls/03_slide.png';
import fourthSlide from '../../../assets/images/conference-halls/04_slide.png';
import SeveralImagesSlider from '../../ReusableElements/SeveralImagesSlider/SeveralImagesSlider';
import CallOrderForm from '../../CallOrderForm/CallOrderForm';
import './ConferenceServices.scss';

export default function ConferenceServices() {
  const { t, i18n } = useTranslation(['service_pages', 'buttons']);

  const availableWindowWidth = document.documentElement.clientWidth;
  const breakpoints = [1441, 601];
  const conferenceHallImages = [firstSlide, secondSlide, thirdSlide, fourthSlide];

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'instant',
    });
  }, []);
  const ldJson = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Івано-Франківська торгово-промислова палата',
    url: 'https://cci.if.ua/',
    logo: 'https://cci.if.ua/static/media/logo_small.088e05e0446873d0c8dfb27546b82194.svg',
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: '+380342523347',
      contactType: 'customer service',
      areaServed: 'UA',
      availableLanguage: 'Ukrainian',
    },
    sameAs: [
      'https://www.facebook.com/cci.if.ua/',
      'https://www.instagram.com/if.cci.ua/',
    ],
  };
  return (
    <section className="ConferenceServices">
      <Helmet>
        <title>
          Як забронювати конференц-зали та кімнату для перемовин у Івано-Франківській ТПП?
        </title>
        <meta
          name="description"
          content="Дізнайтеся, як легко та швидко забронювати конференц-зали для великих груп
          та кімнату для перемовин для менших зустрічей у Торгово-промисловій палаті Івано-Франківська."
        />
        <script type="application/ld+json">
          {JSON.stringify(ldJson)}
        </script>
      </Helmet>
      <div className="wrapper">
        <div className="conference-services-content">
          <div className="conference-services-content-top-row">
            <a
              className="previous-page-link"
              href={
                i18n.languages[0] === 'en'
                  ? '/en/'
                  : '/'
              }
            >
              {t('previous_page_title')}
            </a>
            <div className="conference-services-content-top-row__right-side">
              <h1 className="heading-one">
                {t('heading.conference_services')}
              </h1>
              <p className="description">
                {t('description')}
              </p>
              <div className="types-of-service">
                <p className="paragraph paragraph_bold-font">
                  До Ваших послуг конференц-зали Івано-Франківської торгово-промислової палати (ТПП) з вигідним місцем
                  розташування (приміщення розміщене в центральній частині міста):
                </p>
                <ul>
                  <li>
                    конференц-зали (до 130 осіб і до 25 осіб);
                  </li>
                  <li>
                    кімната для перемовин (до 11 осіб);
                  </li>
                  <li>
                    конференц-харчування (кава-брейк, фуршети);
                  </li>
                  <li>
                    парковка.
                  </li>
                </ul>
              </div>
              <div className="buttons">
                <Button
                  priority="primary"
                  link="#"
                  download
                >
                  {t('buttons:download_the_form')}
                </Button>
                <Button
                  priority="secondary"
                  link="#"
                  target="_blank"
                >
                  {t('buttons:view_the_sample')}
                </Button>
              </div>
              <div className="if-tpp-advice">
                <p className="paragraph paragraph_bold-font">
                  #Порада від Івано-Франківської ТПП
                </p>
                <p className="paragraph">
                  Приміщення оснащене мобільними стільцями, системою кондиціонування, усім необхідним презентаційним
                  обладнанням (високошвидкісна multi-SSID Wi-Fi мережа, фліп-чарт, ноутбук, проектор, стаціонарні та
                  радіомікрофони, електронна указка), а також, при необхідності, забезпечуються столи для роботи.
                </p>
                <p className="paragraph paragraph_bold-font">
                  Додатково надаються послуги:
                </p>
                <ul>
                  <li>
                    організація події (підбір цільової аудиторії, запрошення гостей, промо-компанія тощо);
                  </li>
                  <li>
                    організація фуршетів і кава-брейків в фуршетному залі, який розміщений на одному поверсі з
                    конференц-залами.
                  </li>
                </ul>
                <p className="paragraph paragraph_bold-font">
                  У зв'язку із карантинними обмеженями ми трансформували наш конференц-зал згідно усіх карантинних
                  вимог:
                </p>
                <ul>
                  <li>
                    заходи за участі не більше 15 осіб;
                  </li>
                  <li>
                    регулярна дезінфекція приміщення;
                  </li>
                  <li>
                    соціальна дистанція;
                  </li>
                  <li>
                    захисні маски.
                  </li>
                </ul>
                <p className="paragraph paragraph_bold-font">
                  Для того, щоб отримати більше інформації, зв'яжіться з нами зручним для Вас способом:
                  <br />
                  тел.: +38 (0342) 523346, 523347, +380 50 28 29 794, +380 96 150 50 88;
                  <br />
                  e-mail: info@cci.if.ua/fea@cci.if.ua.
                  <br />
                  Контактна особа – Хмільовська Оксана Ярославівна.
                </p>
              </div>
            </div>
          </div>
          <div className="conference-services-slider-block">
            <SeveralImagesSlider
              isAutoplay
              isArrows={availableWindowWidth > 834}
              isDots={availableWindowWidth <= 834}
              breakpoints={breakpoints}
            >
              {
                conferenceHallImages.map((conferenceHallImage) => (
                  <div
                    key={uuidv4()}
                    className="slide-block"
                  >
                    <img
                      className="conference-hall-image"
                      src={conferenceHallImage}
                      alt="conferenceHallImage"
                    />
                  </div>
                ))
              }
            </SeveralImagesSlider>
          </div>
          <h2 className="heading-two heading-two_form">
            {t('call_order_form_heading')}
          </h2>
          <p className="description">
            {t('call_order_form_description')}
          </p>
          <CallOrderForm />
        </div>
      </div>
    </section>
  );
}
