/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { setArticlesCurrentPage } from '../../../store/actions/articlesActions';
import ArticleItem from '../ArticleItem/ArticleItem';
import ListLoader from '../../ReusableElements/ListLoader/ListLoader';
import Paginator from '../../ReusableElements/Paginator/Paginator';
import './ArticleList.scss';

export default function ArticleList({
  articleList,
  pageCount,
  currentPage,
  minimumPageRequiredHeight,
}) {
  const { t, i18n } = useTranslation('not_found_messages');
  const dispatch = useDispatch();

  const paginatorHelper = (e) => {
    dispatch(setArticlesCurrentPage(e.selected + 1));
  };

  useEffect(() => {
    if (currentPage > 1) {
      const newsEventsPageContent = document.querySelector('.news-events-page-content');
      const previousPageLink = document.querySelector('.previous-page-link');
      const headingOne = document.querySelector('.heading-one');
      const description = document.querySelector('.description');
      const articleListContainer = document.querySelector('.article-list');
      const { paddingTop, paddingBottom } = getComputedStyle(newsEventsPageContent);
      const minimumListRequiredHeight = minimumPageRequiredHeight - (parseFloat(paddingTop) + parseFloat(paddingBottom) + previousPageLink.offsetHeight + headingOne.offsetHeight + description.offsetHeight);

      articleListContainer.style.minHeight = `${minimumListRequiredHeight}px`;
    }
  }, [currentPage]);

  return (
    <>
      <div className="article-list">
        {
          currentPage
            ? articleList[currentPage] && articleList[currentPage].map((articleItem) => (
              <Link
                key={uuidv4()}
                to={
                  i18n.languages[0] === 'en'
                    ? `/news_events/${articleItem.id}/en/`
                    : `/news_events/${articleItem.id}/`
                }
              >
                <ArticleItem data={articleItem} />
              </Link>
            ))
            : articleList[1] && articleList[1].map((articleItem) => (
              <Link
                key={uuidv4()}
                to={
                  i18n.languages[0] === 'en'
                    ? `/news_events/${articleItem.id}/en/`
                    : `/news_events/${articleItem.id}/`
                }
              >
                <ArticleItem data={articleItem} />
              </Link>
            ))
        }
        {
          currentPage > 1 && !articleList[currentPage] && <ListLoader />
        }
      </div>
      {
        pageCount > 1
          ? currentPage && (
            <Paginator
              pageCount={pageCount}
              currentPage={currentPage}
              handlePageClick={paginatorHelper}
            />
          )
          : pageCount === 0 && (
            <p className="articles-not-found">
              {t('articles_not_found')}
            </p>
          )
      }
    </>
  );
}
