/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import AddsFileInput from '../../FileInput/AddsFileInput';
import { postAdvertising, updateAdvertising } from '../../../../requests/requests';
import './Advertising.scss';

export default function Advertising({
  data, onChangeView,
}) {
  const [advertisingData, setAdvertisingData] = useState({ ...data });
  const [title, setTitle] = useState(data.title);
  const [description, setDescription] = useState(data.description);
  const [url, setUrl] = useState(data.url);
  const [id] = useState(data.id);

  const onAdvertisingChange = (event) => {
    const { name, value } = event.target;
    const dataCopy = { ...advertisingData };
    dataCopy[name] = value;
    setAdvertisingData(dataCopy);
  };

  const onInputChange = (event) => {
    switch (event.target.name) {
      case 'title':
        setTitle(event.target.value);
        break;
      case 'description':
        setDescription(event.target.value);
        break;
      case 'url':
        setUrl(event.target.value);
        break;
      default:
        break;
    }

    onAdvertisingChange(event);
  };

  const onAdvertisingSend = () => {
    const formData = { advertising: advertisingData };
    if (advertisingData.id) {
      updateAdvertising(advertisingData.id, formData)
        .then((response) => {
          if (response.status === 200) {
            onChangeView();
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } else {
      postAdvertising(formData)
        .then((response) => {
          if (response.status === 200) {
            onChangeView();
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };
  console.log(advertisingData);
  return (
    <tr>
      <td>
        <p>title</p>
        <input name="title" value={title} onChange={onInputChange} />
      </td>
      <td>
        <p>description</p>
        <input name="description" value={description} onChange={onInputChange} />
      </td>
      <td>
        <p>
          url
          {id}
        </p>
        <input name="url" value={url} onChange={onInputChange} />
      </td>
      <td>
        <AddsFileInput>
          {id}
        </AddsFileInput>
        <input type="hidden" name="id" value={advertisingData.id} />
      </td>
      <td>
        <button type="button" className="admin_button" onClick={onChangeView}>Скасувати</button>
      </td>
      <td>
        <button type="submit" className="admin_button" onClick={onAdvertisingSend}>Зберегти</button>
      </td>
    </tr>
  );
}
