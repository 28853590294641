/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import ManagerItem from '../ManagerItem/ManagerItem';
import './ManagerList.scss';

export default function ManagerList({ managerData }) {
  const { t } = useTranslation('not_found_messages');

  return (
    <>
      <div className="manager-list">
        {
          managerData.map((managerItem) => (
            <ManagerItem
              key={uuidv4()}
              data={managerItem}
            />
          ))
        }
      </div>
      {
        managerData.length === 0 && (
          <p className="managers-not-found">
            {t('managers_not_found')}
          </p>
        )
      }
    </>
  );
}
