/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { setCompaniesCurrentPage } from '../../store/actions/companiesActions';
import CompanyItem from '../CompanyItem/CompanyItem';
import ListLoader from '../ReusableElements/ListLoader/ListLoader';
import Paginator from '../ReusableElements/Paginator/Paginator';
import './CompanyList.scss';

export default function CompanyList({
  companyList,
  pageCount,
  currentPage,
  minimumPageRequiredHeight,
}) {
  const { t } = useTranslation('not_found_messages');
  const dispatch = useDispatch();

  const paginatorHelper = (e) => {
    dispatch(setCompaniesCurrentPage(e.selected + 1));
  };

  useEffect(() => {
    if (currentPage > 1) {
      const communityPageContent = document.querySelector('.community-page-content');
      const communityPageContentTopRow = document.querySelector('.community-page-content-top-row');
      const companyListContainer = document.querySelector('.company-list');
      const { paddingTop, paddingBottom } = getComputedStyle(communityPageContent);
      const minimumListRequiredHeight = minimumPageRequiredHeight - (parseFloat(paddingTop) + parseFloat(paddingBottom) + communityPageContentTopRow.offsetHeight);

      companyListContainer.style.minHeight = `${minimumListRequiredHeight}px`;
    }
  }, [currentPage]);

  return (
    <>
      <div className="company-list">
        {
          companyList[currentPage] && companyList[currentPage].map((companyItem) => (
            <CompanyItem
              key={uuidv4()}
              data={companyItem}
            />
          ))
        }
        {
          currentPage > 1 && !companyList[currentPage] && <ListLoader />
        }
      </div>
      {
        pageCount > 1
          ? (
            <Paginator
              pageCount={pageCount}
              currentPage={currentPage}
              handlePageClick={paginatorHelper}
            />
          )
          : pageCount === 0 && (
            <p className="companies-not-found">
              {t('companies_not_found')}
            </p>
          )
      }
    </>
  );
}
