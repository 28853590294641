/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React from 'react';
import addFile from '../../../assets/icons/add-file.svg';
import './FileInput.scss';
import { postAddsImages } from '../../../requests/requests';

export default function AddsFileInput(id) {
  const action = (event) => {
    const { files } = event.target;
    postAddsImages(id, files);
  };

  return (
    <label className="AddsFileInput">
      <input
        type="file"
        name="test"
        multiple
        onChange={action}
      />
      <img
        src={addFile}
        alt="addFile"
      />
    </label>
  );
}
