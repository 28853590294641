/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import './ManagerInfo.scss';
import { deleteManager } from '../../../../requests/requests';

export default function ManagerInfo({
  id, name, surname, position, description, email, phone, onChangeView,
}) {
  useEffect(() => {}, []);
  const onDelete = () => {
    deleteManager(id);
  };
  return (
    <tr>
      <td>{id}</td>
      <td>{name}</td>
      <td>{surname}</td>
      <td>{position}</td>
      <td>{description}</td>
      <td>{email}</td>
      <td>{phone}</td>
      <td><button className="admin_button" type="button" onClick={onChangeView}>Редагувати</button></td>
      <td><button className="admin_button" type="button" onClick={onDelete}>Видалити</button></td>
    </tr>
  );
}
