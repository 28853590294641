/* eslint-disable react/prop-types */
import React from 'react';
import phoneGreen from '../../../assets/icons/info/phone_green.svg';
import email from '../../../assets/icons/info/email.svg';
import './ManagerItem.scss';

export default function ManagerItem({ data }) {
  return (
    <div className="manager-list__item">
      <div className="manager-list__person-photo">
        <img
          src={data.photo?.url}
          alt={`${data.name} ${data.surname}`}
          loading="lazy"
        />
      </div>
      <div className="manager-list__person-info">
        <div className="person-info-item">
          <p className="primary-paragraph">
            {`${data.name} ${data.surname}`}
          </p>
          <p className="primary-paragraph">
            {data.position}
          </p>
        </div>
        <div className="person-info-item">
          <p className="secondary-paragraph">
            {data.description}
          </p>
        </div>
        <div className="person-info-item">
          <img
            className="info-icon"
            src={phoneGreen}
            alt="phoneGreen"
          />
          <p className="secondary-paragraph">
            {data.phone}
          </p>
        </div>
        <div className="person-info-item">
          <img
            className="info-icon"
            src={email}
            alt="email"
          />
          <p className="secondary-paragraph">
            {data.email}
          </p>
        </div>
      </div>
    </div>
  );
}
