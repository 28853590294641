/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Slider from 'react-slick';
import leftArrow from '../../../assets/icons/left-arrow.svg';
import rightArrow from '../../../assets/icons/right-arrow.svg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './SeveralImagesSlider.scss';

export default function SeveralImagesSlider({
  origin,
  scrollAtOnce = [1, 1, 1],
  scrollSpeed = [2000, 2000, 2000],
  isAutoplay = false,
  autoplayScrollSpeed = [7000, 7000, 7000],
  isArrows = false,
  isDots = false,
  breakpoints,
  children,
}) {
  const [isSlideHeightDefined, setIsSlideHeightDefined] = useState(false);

  const previous = (
    <img
      src={leftArrow}
      alt="leftArrow"
    />
  );
  const next = (
    <img
      src={rightArrow}
      alt="rightArrow"
    />
  );

  const reInitHandler = () => {
    let slickTrack;
    const availableWindowWidth = document.documentElement.clientWidth;

    if (origin === 'activities') {
      slickTrack = document.querySelector('.activities-slider-block .slick-track');
      const activities = document.querySelectorAll('.activities-slider-block .activity-item');
      const images = document.querySelectorAll('.activities-slider-block .activity-image');

      let allImagesAreReadyToShow = true;

      for (const image of images) {
        if (image.clientHeight === 0) {
          allImagesAreReadyToShow = false;
        }
      }

      if (!isSlideHeightDefined && allImagesAreReadyToShow) {
        for (const activity of activities) {
          activity.style.height = `${slickTrack.clientHeight}px`;

          if (availableWindowWidth > 1600) {
            activity.style.minHeight = '325px';
          } else if (availableWindowWidth <= 1600 && availableWindowWidth > 1280) {
            activity.style.minHeight = '290px';
          } else if (availableWindowWidth <= 1280 && availableWindowWidth > 1024) {
            activity.style.minHeight = '310px';
          } else if (availableWindowWidth <= 1024 && availableWindowWidth > 834) {
            activity.style.minHeight = '255px';
          } else if (availableWindowWidth <= 834 && availableWindowWidth > 768) {
            activity.style.minHeight = '230px';
          } else if (availableWindowWidth <= 768 && availableWindowWidth > 601) {
            activity.style.minHeight = '205px';
          } else if (availableWindowWidth <= 601 && availableWindowWidth > 428) {
            activity.style.minHeight = '275px';
          } else if (availableWindowWidth <= 428) {
            activity.style.minHeight = '240px';
          }

          setIsSlideHeightDefined(true);
        }
      }
    } else if (origin === 'our-partners') {
      slickTrack = document.querySelector('.vip-company-list .slick-track');
      const companies = document.querySelectorAll('.vip-company-list a');
      const logos = document.querySelectorAll('.vip-company-list .vip-company-list__logo');

      let allLogosAreReadyToShow = true;

      for (const logo of logos) {
        if (logo.clientHeight === 0) {
          allLogosAreReadyToShow = false;
        }
      }

      if (!isSlideHeightDefined && allLogosAreReadyToShow) {
        for (const company of companies) {
          company.style.height = `${slickTrack.clientHeight}px`;

          setIsSlideHeightDefined(true);
        }
      }
    }
  };

  const settings = {
    slidesToShow: 3,
    slidesToScroll: scrollAtOnce[0],
    speed: scrollSpeed[0],
    autoplay: isAutoplay,
    autoplaySpeed: autoplayScrollSpeed[0],
    draggable: false,
    arrows: isArrows,
    prevArrow: previous,
    nextArrow: next,
    dots: isDots,
    responsive: [
      {
        breakpoint: breakpoints[0],
        settings: {
          slidesToShow: 2,
          slidesToScroll: scrollAtOnce[1],
          speed: scrollSpeed[1],
          autoplaySpeed: autoplayScrollSpeed[1],
        },
      },
      {
        breakpoint: breakpoints[1],
        settings: {
          slidesToShow: 1,
          slidesToScroll: scrollAtOnce[2],
          speed: scrollSpeed[2],
          autoplaySpeed: autoplayScrollSpeed[2],
        },
      },
    ],
    onReInit: reInitHandler,
  };

  return (
    <Slider {...settings}>
      {children}
    </Slider>
  );
}
