/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { setEventsCurrentPage } from '../../store/actions/eventsActions';
import ActivityItem from '../ActivityItem/ActivityItem';
import ListLoader from '../ReusableElements/ListLoader/ListLoader';
import Paginator from '../ReusableElements/Paginator/Paginator';
import './ActivityList.scss';

export default function ActivityList({
  eventList,
  pageCount,
  currentPage,
  minimumPageRequiredHeight,
}) {
  const { t } = useTranslation('not_found_messages');
  const dispatch = useDispatch();

  const className = classNames('activity-list', { loading: currentPage > 1 && !eventList[currentPage] });

  const paginatorHelper = (e) => {
    dispatch(setEventsCurrentPage(e.selected + 1));
  };

  useEffect(() => {
    if (currentPage > 1) {
      const activitiesPageContent = document.querySelector('.activities-page-content');
      const previousPageLink = document.querySelector('.previous-page-link');
      const headingOne = document.querySelector('.heading-one');
      const description = document.querySelector('.description');
      const activityList = document.querySelector('.activity-list');
      const { paddingTop, paddingBottom } = getComputedStyle(activitiesPageContent);
      const minimumListRequiredHeight = minimumPageRequiredHeight - (parseFloat(paddingTop) + parseFloat(paddingBottom) + previousPageLink.offsetHeight + headingOne.offsetHeight + description.offsetHeight);

      activityList.style.minHeight = `${minimumListRequiredHeight}px`;
    }
  }, [currentPage]);

  return (
    <>
      <div className={className}>
        {
          eventList[currentPage] && eventList[currentPage].map((eventItem) => (
            <ActivityItem
              key={uuidv4()}
              data={eventItem}
            />
          ))
        }
        {
          currentPage > 1 && !eventList[currentPage] && <ListLoader />
        }
      </div>
      {
        pageCount > 1
          ? (
            <Paginator
              pageCount={pageCount}
              currentPage={currentPage}
              handlePageClick={paginatorHelper}
            />
          )
          : pageCount === 0 && (
            <p className="activities-not-found">
              {t('activities_not_found')}
            </p>
          )
      }
    </>
  );
}
