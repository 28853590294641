/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import './User.scss';

export default function User({
  id, name, email, status,
}) {
  useEffect(() => {}, []);

  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(name);
  const [editedEmail, setEditedEmail] = useState(email);
  const [editedStatus, setEditedStatus] = useState(status);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    // Perform save operation here
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedName(name);
    setEditedEmail(email);
    setEditedStatus(status);
  };
  const onDelete = () => {
    // Perform delete operation here
  };
  return (
    <tr>
      <td>{id}</td>
      {isEditing ? (
        <>
          <td>
            <p>Імʼя:</p>
            <input id="editedName" type="text" value={editedName} onChange={(e) => setEditedName(e.target.value)} />
          </td>
          <td>
            <p>Email:</p>
            <input id="editedEmail" type="text" value={editedEmail} onChange={(e) => setEditedEmail(e.target.value)} />
          </td>
          <td>
            <p>Статус:</p>
            <input id="editedStatus" type="text" value={editedStatus} onChange={(e) => setEditedStatus(e.target.value)} />
          </td>
        </>
      ) : (
        <>
          <td>{name}</td>
          <td>{email}</td>
          <td>{status}</td>
        </>
      )}
      <td>
        {isEditing ? (
          <>
            <button type="button" className="admin_button" onClick={handleSave}>Save</button>
            <button type="button" className="admin_button" onClick={handleCancel}>Cancel</button>
          </>
        ) : (
          <button type="button" className="admin_button" onClick={handleEdit}>Edit</button>
        )}
      </td>
      <td><button type="button" className="admin_button" onClick={onDelete}>Delete</button></td>
    </tr>
  );
}
