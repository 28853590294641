/* eslint-disable react/no-danger */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import { v4 as uuidv4 } from 'uuid';
import articleAPI from '../../store/actions/articleActions';
import calendarGreen from '../../assets/icons/info/calendar_green.svg';
import person from '../../assets/icons/info/person.svg';
import eye from '../../assets/icons/info/eye.svg';
import SeveralImagesSlider from '../ReusableElements/SeveralImagesSlider/SeveralImagesSlider';
import ArticleCommentForm from '../ArticleCommentForm/ArticleCommentForm';
import './ArticlePage.scss';

export default function ArticlePage() {
  const { t, i18n } = useTranslation(['article_page', 'notifications']);
  const dispatch = useDispatch();
  const {
    data,
    isReady,
    notification,
  } = useSelector((state) => state.article);
  const { articleId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const availableWindowWidth = document.documentElement.clientWidth;
  const breakpoints = [1441, 601];

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'instant',
    });

    dispatch(articleAPI(articleId));
  }, [articleId]);

  useEffect(() => {
    const header = document.querySelector('.Header.fixed');
    const articlePage = document.querySelector('.ArticlePage');
    const footer = document.querySelector('.Footer');
    const minimumRequiredHeight = document.documentElement.clientHeight - (header.offsetHeight + footer.offsetHeight);

    articlePage.style.minHeight = `${minimumRequiredHeight}px`;
  }, []);
  const ldJson = {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': 'https://cci.if.ua/news_events/84/',
    },
    headline: data.title,
    description: data.body,
    image: data.cover?.url,
    author: {
      '@type': 'Organization',
      name: 'Івано-Франківська торгово-промислова палата',
      url: 'https://cci.if.ua/',
    },
    publisher: {
      '@type': 'Organization',
      name: '',
      logo: {
        '@type': 'ImageObject',
        url: '',
      },
    },
    datePublished: data.publication_date,
  };
  return (
    <section className="ArticlePage">
      <Helmet>
        <title>
          {data.title}
        </title>
        <meta
          name="description"
          content={data.title}
        />
        <script type="application/ld+json">
          {JSON.stringify(ldJson)}
        </script>
      </Helmet>
      <div className="wrapper">
        <div className="article-page-content">
          <button
            className="previous-page-link"
            type="button"
            onClick={
              pathname === `/search_results/${articleId}/en/` || pathname === `/search_results/${articleId}/`
                ? () => navigate(
                  i18n.languages[0] === 'en'
                    ? '/search_results/en/'
                    : '/search_results/',
                )
                : () => navigate(
                  i18n.languages[0] === 'en'
                    ? '/news_events/en/'
                    : '/news_events/',
                )
            }
          >
            {
              pathname === `/search_results/${articleId}/en/` || pathname === `/search_results/${articleId}/`
                ? t('previous_page_title.search_results')
                : t('previous_page_title.news_events')
            }
          </button>
          {
            isReady
              ? (
                <>
                  <h1 className="heading-one">
                    {data.title}
                  </h1>
                  <img
                    className="article-cover"
                    src={data.cover?.url}
                    alt={data.title}
                  />
                  <div className="article-details">
                    <div className="article-info">
                      <div className="info-item">
                        <img
                          className="info-icon"
                          src={calendarGreen}
                          alt="calendarGreen"
                        />
                        <p className="paragraph">
                          {`${t('info.created_at')}: `}
                          <Moment format="YYYY-MM-DD">
                            {data.publication_date}
                          </Moment>
                        </p>
                      </div>
                      {
                        data.author && (
                          <div className="info-item">
                            <img
                              className="info-icon"
                              src={person}
                              alt="person"
                            />
                            <p className="paragraph">
                              {`${t('info.author')}: ${data.author}`}
                            </p>
                          </div>
                        )
                      }
                      <div className="info-item">
                        <img
                          className="info-icon"
                          src={eye}
                          alt="eye"
                        />
                        <p className="paragraph">
                          {`${t('info.views')}: ${data.viewed}`}
                        </p>
                      </div>
                    </div>
                    <p
                      className="article-body"
                      dangerouslySetInnerHTML={{ __html: data.body }}
                    />
                  </div>
                  {
                    data.images.length === 1 && (
                      <img
                        className="single-photo"
                        src={data.images[0].url}
                        alt="singlePhoto"
                      />
                    )
                  }
                  {
                    data.images.length === 2 && (
                      <div className="additional-photos">
                        {
                          data.images.map((image) => (
                            <img
                              key={uuidv4()}
                              className="additional-photo"
                              src={image.url}
                              alt="additionalPhoto"
                            />
                          ))
                        }
                      </div>
                    )
                  }
                  {
                    data.images.length > 2 && (
                      <div className="article-slider-block">
                        <SeveralImagesSlider
                          isAutoplay
                          isArrows={availableWindowWidth > 834}
                          isDots={availableWindowWidth <= 834}
                          breakpoints={breakpoints}
                        >
                          {
                            data.images.map((image) => (
                              <div
                                key={uuidv4()}
                                className="slide-block"
                              >
                                <img
                                  className="additional-slide"
                                  src={image.url}
                                  alt="additionalSlide"
                                />
                              </div>
                            ))
                          }
                        </SeveralImagesSlider>
                      </div>
                    )
                  }
                  <h2 className="heading-two heading-two_form">
                    {t('article_comment_form_heading')}
                  </h2>
                  <p className="form-description">
                    {t('article_comment_form_description')}
                  </p>
                  <ArticleCommentForm />
                </>
              )
              : (
                <p className="notification">
                  {t(`notifications:${notification}`, notification)}
                </p>
              )
          }
        </div>
      </div>
    </section>
  );
}
