/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import SeveralImagesSlider from '../../ReusableElements/SeveralImagesSlider/SeveralImagesSlider';
import VIPCompanyItem from '../VIPCompanyItem/VIPCompanyItem';
import './VIPCompanyList.scss';

export default function VIPCompanyList({ vipCompanyData }) {
  const { t } = useTranslation('not_found_messages');

  const availableWindowWidth = document.documentElement.clientWidth;
  const breakpoints = [602, 360];
  const vipCompanies = vipCompanyData.map((vipCompanyItem) => (
    <a
      key={uuidv4()}
      href={vipCompanyItem.website}
      target="_blank"
      rel="noreferrer"
      aria-label="vip-company-link"
    >
      <VIPCompanyItem data={vipCompanyItem} />
    </a>
  ));

  return (
    <>
      <div className="vip-company-list">
        {
          availableWindowWidth <= 1024
            ? (
              <SeveralImagesSlider
                origin="our-partners"
                scrollAtOnce={[3, 2, 1]}
                scrollSpeed={[3000, 2000, 1000]}
                isAutoplay
                autoplayScrollSpeed={[8000, 7000, 6000]}
                isDots
                breakpoints={breakpoints}
              >
                {vipCompanies}
              </SeveralImagesSlider>
            )
            : vipCompanies
        }
      </div>
      {
        vipCompanyData.length === 0 && (
          <p className="vip-companies-not-found">
            {t('vip_companies_not_found')}
          </p>
        )
      }
    </>
  );
}
