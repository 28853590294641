/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react';
import './Button.scss';

function Button({
  handle_click, name, width, clicked, className = 'admin_button',
}) {
  return (
    <span>
      <button type="button" style={{ width, background: clicked ? '#A8A7EE' : '#D9D9D9' }} className={className} onClick={handle_click}>{name}</button>
    </span>
  );
}

export default Button;
