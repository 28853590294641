/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import logoBig from '../../../assets/images/logos/TPP/logo_big.svg';
import landingMainSlides from '../../../constants/landingMainSlides';
import OneImageSlider from '../../ReusableElements/OneImageSlider/OneImageSlider';
import './Main.scss';

export default function Main() {
  const { t } = useTranslation('landing_page', { keyPrefix: 'main' });

  useEffect(() => {
    const header = document.querySelector('.Header');
    const mainAnchor = document.querySelector('#main');

    mainAnchor.style.top = `-${header.offsetHeight}px`;
  }, []);

  return (
    <section className="Main">
      <div id="main" className="anchor" />
      <div className="wrapper">
        <div className="main-content">
          <div className="main-content-top-row">
            <img
              className="logo-big"
              src={logoBig}
              alt="logoBig"
            />
            <div className="main-content-top-row__right-side">
              <h1 className="heading-one">
                {t('heading_top')}
              </h1>
              <p className="description-top">
                {t('description_top')}
              </p>
            </div>
          </div>
          <div className="main-content-bottom-row">
            <OneImageSlider
              slides={landingMainSlides}
              isRenderItem
              namespace="landing_page"
            />
            {/* <div className="advertisement-banners">
              <div className="banner">Рекламний банер</div>
              <div className="banner">Рекламний банер</div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}
