/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import EventInfo from '../EventInfo/EventInfo';
import { getEvents } from '../../../../requests/requests';
import './EventList.scss';

export default function EventList() {
  const [eventData, setEventData] = useState();

  useEffect(() => {
    getEvents()
      .then((response) => {
        console.log(response.data);
        setEventData(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  return (
    <section className="EventList">
      <table className="events-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Заголовок</th>
            <th>Опис</th>
            {/* <th>Координатор</th>
            <th>Email</th>
            <th>Телефон</th> */}
            <th>Дата події</th>
          </tr>
        </thead>
        <tbody>
          {eventData && eventData.map((event) => (
            <EventInfo
              id={event.id}
              title={event.title}
              description={event.description}
              // coordinator={event.coordinator}
              // email={event.email}
              // phone={event.phone}
              eventDate={event.event_date}
            />
          ))}
        </tbody>
      </table>
    </section>
  );
}
