/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { articlesAPI } from '../../store/actions/articlesActions';
import ArticleList from '../LandingPage/ArticleList/ArticleList';
import './NewsEventsPage.scss';

export default function NewsEventsPage() {
  const { t, i18n } = useTranslation(['news_events_page', 'notifications']);
  const [minimumPageRequiredHeight, setMinimumPageRequiredHeight] = useState();
  const dispatch = useDispatch();
  const {
    articleList,
    perPage,
    pageCount,
    currentPage,
    isReady,
    notification,
  } = useSelector((state) => state.articles);

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'instant',
    });

    dispatch(articlesAPI());
  }, [currentPage]);

  useEffect(() => {
    const header = document.querySelector('.Header.fixed');
    const newsEventsPage = document.querySelector('.NewsEventsPage');
    const footer = document.querySelector('.Footer');
    const minimumRequiredHeight = document.documentElement.clientHeight - (header.offsetHeight + footer.offsetHeight);

    newsEventsPage.style.minHeight = `${minimumRequiredHeight}px`;
    setMinimumPageRequiredHeight(minimumRequiredHeight);
  }, []);

  return (
    <section className="NewsEventsPage">
      <Helmet>
        <title>
          Які останні новини та події від Івано-Франківської торгово-промислової палати (ІФ ТПП)?
        </title>
        <meta
          name="description"
          content="Дізнайтеся про останні новини,
          анонси подій та важливі оголошення від
          Івано-Франківської торгово-промислової палати (ІФ ТПП)."
        />
      </Helmet>
      <div className="wrapper">
        <div className="news-events-page-content">
          <a
            className="previous-page-link"
            href={
              i18n.languages[0] === 'en'
                ? '/en/'
                : '/'
            }
          >
            {t('previous_page_title')}
          </a>
          <h1 className="heading-one">
            {t('heading')}
          </h1>
          <p className="description">
            {t('description')}
          </p>
          {
            perPage && pageCount !== null && currentPage && isReady
              ? (
                <ArticleList
                  articleList={articleList}
                  pageCount={pageCount}
                  currentPage={currentPage}
                  minimumPageRequiredHeight={minimumPageRequiredHeight}
                />
              )
              : (
                <p className="notification">
                  {t(`notifications:${notification}`, notification)}
                </p>
              )
          }
        </div>
      </div>
    </section>
  );
}
