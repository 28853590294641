/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  hideHamburgerMenu,
  hideMainDropdownList,
  hideDocumentsDropdownList,
  hideProposalsForCCIDropdownList,
  hideSearchForm,
  hideLanguageDropdownList,
} from './store/actions/menusActions';
// import { showLogIn, showHotline } from './store/actions/modalWindowsActions';
import { showLogIn } from './store/actions/modalWindowsActions';
import Header from './Components/LandingPage/Header/Header';
import Footer from './Components/LandingPage/Footer/Footer';
// import phoneWhite from './assets/icons/phone_white.svg';
// import Hotline from './Components/ModalWindows/Hotline/Hotline';
import './App.scss';

export default function App() {
  const { t, i18n } = useTranslation('buttons');
  const dispatch = useDispatch();
  const {
    isHamburgerMenuActive,
    isMainDropdownListVisible,
    isDocumentsDropdownListVisible,
    isProposalsForCCIDropdownListVisible,
    isSearchFormActive,
    isLanguageDropdownListVisible,
  } = useSelector((state) => state.menus);
  // const { isHotlineVisible } = useSelector((state) => state.modalWindows);
  const { isInfoMessageVisible } = useSelector((state) => state.modalWindows.infoMessage);
  const { isRegistrationVisible } = useSelector((state) => state.modalWindows.registration);
  const { isLogInVisible } = useSelector((state) => state.modalWindows);
  const { isUploadFilesVisible } = useSelector((state) => state.modalWindows);
  const navigate = useNavigate();

  const token = localStorage.getItem('AUTH_TOKEN');

  const clickHandler = () => {
    if (isHamburgerMenuActive) {
      dispatch(hideHamburgerMenu());
    }

    if (isMainDropdownListVisible) {
      dispatch(hideMainDropdownList());
    }

    if (isDocumentsDropdownListVisible) {
      dispatch(hideDocumentsDropdownList());
    }

    if (isProposalsForCCIDropdownListVisible) {
      dispatch(hideProposalsForCCIDropdownList());
    }

    if (isSearchFormActive) {
      dispatch(hideSearchForm());
    }

    if (isLanguageDropdownListVisible) {
      dispatch(hideLanguageDropdownList());
    }
  };

  const {
    protocol,
    host,
    pathname,
    search,
    hash,
    href,
  } = window.location;

  useEffect(() => {
    let finalProtocol;
    let finalHost;
    let finalPathname;

    if (!host.endsWith('localhost:3000') && protocol === 'http:') {
      finalProtocol = 'https:';
    } else {
      finalProtocol = protocol;
    }

    if (host.startsWith('www.')) {
      finalHost = host.slice(4);
    } else {
      finalHost = host;
    }

    if (pathname.endsWith('/')) {
      finalPathname = pathname;
    } else {
      finalPathname = `${pathname}/`;
    }

    if (i18n.languages[0] === 'en' && !pathname.endsWith('en/')) {
      finalPathname = `${finalPathname}en/`;
    } else if (i18n.languages[0] === 'uk' && pathname.endsWith('en/')) {
      finalPathname = finalPathname.slice(0, finalPathname.length - 3);
    }

    const finalHref = `${finalProtocol}//${finalHost}${finalPathname}${search}${hash}`;

    if (finalHref !== href) {
      window.location.assign(finalHref);
    }

    // if (token) {
    //   if (finalHref !== href) {
    //     window.location.assign(finalHref);
    //   }
    // } else if (pathname.startsWith('/personal_account/')) {
    //   navigate(
    //     i18n.languages[0] === 'en'
    //       ? '/en/'
    //       : '/',
    //   );
    //   dispatch(showLogIn());
    // } else if (finalHref !== href) {
    //   window.location.assign(finalHref);
    // }

    const link = document.createElement('link');

    link.rel = 'alternate';
    [link.hreflang] = i18n.languages;
    link.href = href;

    const existingLink = document.querySelector('link[rel="alternate"]');

    if (existingLink) {
      existingLink.replaceWith(link);
    } else {
      document.head.append(link);
    }
  }, [i18n.languages[0], href]);

  useEffect(() => {
    if (pathname === '/en/' || pathname === '/') {
      const meta = document.createElement('meta');

      meta.name = 'google-site-verification';
      meta.content = 'BXUQXVIqeyxfubAz1f1M2FMMKx-yX95a_n7IJRSlIjY';

      document.head.append(meta);
    } else {
      const existingMeta = document.querySelector('meta[name="google-site-verification"]');

      if (existingMeta) {
        existingMeta.remove();
      }
    }
  }, [pathname]);

  useEffect(() => {
    const bingcPassive = document.getElementById('bingc-passive');
    const bingcActive = document.getElementById('bingc-active');
    const bingcPhoneButton = document.getElementById('bingc-phone-button');

    if (isInfoMessageVisible || isRegistrationVisible || isLogInVisible || isUploadFilesVisible) {
      bingcPassive.style.display = 'none';
      bingcActive.style.display = 'none';
      bingcPhoneButton.style.display = 'none';
    } else if (bingcPassive && bingcActive && bingcPhoneButton) {
      bingcPassive.style.display = '';
      bingcActive.style.display = '';
      bingcPhoneButton.style.display = '';
    }
  }, [isInfoMessageVisible, isRegistrationVisible, isLogInVisible, isUploadFilesVisible]);

  return (
    <>
      <div className="App">
        <Header />
        <main
          className="main"
          aria-hidden="true"
          onClick={clickHandler}
        >
          <Outlet />
        </main>
        <Footer />
        {/* <button
          className="hotline-button"
          type="button"
          onClick={() => dispatch(showHotline())}
        >
          <img
            src={phoneWhite}
            alt="phoneWhite"
          />
          <span>
            {t('hotline')}
          </span>
        </button> */}
      </div>
      {/* {
        isHotlineVisible && <Hotline />
      } */}
    </>

  );
}
