/* eslint-disable react/prop-types */
import React from 'react';
import ReactPaginate from 'react-paginate';
import previousLabel from '../../../assets/icons/previous-label.svg';
import nextLabel from '../../../assets/icons/next-label.svg';
import './Paginator.scss';

export default function Paginator({
  pageCount,
  currentPage,
  handlePageClick,
}) {
  const availableWindowWidth = document.documentElement.clientWidth;

  const previous = (
    <div className="previous-label-container">
      <img
        src={previousLabel}
        alt="previousLabel"
      />
    </div>
  );
  const next = (
    <div className="next-label-container">
      <img
        src={nextLabel}
        alt="nextLabel"
      />
    </div>
  );

  return (
    <div className="Paginator">
      <ReactPaginate
        pageCount={pageCount}
        pageRangeDisplayed={3}
        marginPagesDisplayed={availableWindowWidth > 601 ? 2 : 1}
        previousLabel={previous}
        nextLabel={next}
        breakLabel="..."
        containerClassName="container"
        previousClassName="previous"
        previousLinkClassName="previous-link"
        nextClassName="next"
        nextLinkClassName="next-link"
        breakClassName="break"
        breakLinkClassName="break-link"
        pageClassName="page"
        pageLinkClassName="page-link"
        activeClassName="active"
        activeLinkClassName="active-link"
        onPageChange={handlePageClick}
        forcePage={currentPage - 1}
      />
    </div>
  );
}
