/* eslint-disable react/prop-types */
import React from 'react';
import './Submit.scss';

export default function Submit({
  priority,
  isDisabled = false,
  children,
}) {
  return (
    <button
      className={`submit submit_${priority}`}
      type="submit"
      disabled={isDisabled}
    >
      {children}
    </button>
  );
}
