import React, { useEffect } from 'react';
// import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { articlesAPI } from '../../../store/actions/articlesActions';
import ArticleList from '../ArticleList/ArticleList';
import Button from '../../ReusableElements/Buttons/Button/Button';
import './NewsEvents.scss';

export default function NewsEvents() {
  const { t, i18n } = useTranslation(['landing_page', 'notifications', 'buttons']);
  const dispatch = useDispatch();
  const {
    articleList,
    perPage,
    pageCount,
    currentPage,
    isReady,
    notification,
  } = useSelector((state) => state.articles);

  useEffect(() => {
    const header = document.querySelector('.Header');
    const newsEventsAnchor = document.querySelector('#news-events');

    newsEventsAnchor.style.top = `-${header.offsetHeight}px`;

    dispatch(articlesAPI());
  }, []);

  return (
    <section className="NewsEvents">
      <div id="news-events" className="anchor" />
      <div className="wrapper">
        <div className="news-events-content">
          <h2 className="heading-two">
            {t('news_events.heading')}
          </h2>
          <p className="description">
            {t('news_events.description')}
          </p>
          {
            perPage && pageCount !== null && currentPage && isReady
              ? (
                <ArticleList
                  articleList={articleList}
                  pageCount={pageCount}
                />
              )
              : (
                <p className="notification">
                  {t(`notifications:${notification}`, notification)}
                </p>
              )
          }
          <Button
            priority="primary"
            link={
              i18n.languages[0] === 'en'
                ? '/news_events/en/'
                : '/news_events/'
            }
          >
            {t('buttons:learn_more')}
          </Button>
        </div>
      </div>
    </section>
  );
}
