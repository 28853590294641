import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { sendArticleCommentForm } from '../../requests/requests';
import { showInfoMessage } from '../../store/actions/modalWindowsActions';
import OrdinaryInput from '../ReusableElements/FormElemets/OrdinaryInput/OrdinaryInput';
import Textarea from '../ReusableElements/FormElemets/Textarea/Textarea';
import Submit from '../ReusableElements/Buttons/Submit/Submit';
import InfoMessage from '../ModalWindows/InfoMessage/InfoMessage';
import './ArticleCommentForm.scss';

export default function ArticleCommentForm() {
  const { t } = useTranslation(['form_error_messages', 'form_fields', 'buttons', 'info_messages']);
  const dispatch = useDispatch();
  const {
    isInfoMessageVisible,
    contentKey,
  } = useSelector((state) => state.modalWindows.infoMessage);

  const commentOnArticleSchema = Yup.object({
    full_name: Yup
      .string()
      .required(t('article_comment_form.full_name.required'))
      .matches(/^[a-zа-щєіїьюя'"`\s-]+$/i, t('article_comment_form.full_name.matches'))
      .min(2, t('article_comment_form.full_name.min')),
    email: Yup
      .string()
      .required(t('article_comment_form.email.required'))
      .email(t('article_comment_form.email.email')),
    comment: Yup
      .string()
      .trim()
      .required(t('article_comment_form.comment.required')),
  });

  const transform = (enteredValue) => {
    const trimmedValue = enteredValue
      .replace(/^['"`\s-]+/, '')
      .replace(/['"`\s-]+$/, '');
    const valueWithDeletedExtraCharacters = trimmedValue
      .replace(/[a-zа-щєіїьюя]['"`\s-]*\s+['"`\s-]*[a-zа-щєіїьюя]/gi, (str) => `${str[0]} ${str[str.length - 1]}`)
      .replace(/['"`-]+/g, (str) => str[0]);
    const partsOfValue = valueWithDeletedExtraCharacters.split(' ');
    const valueForRegistration = partsOfValue
      .map((str) => str[0].toUpperCase() + str.slice(1).toLowerCase())
      .join(' ')
      .replace(/-[a-zа-щєіїьюя]/gi, (str) => str.toUpperCase());

    return valueForRegistration;
  };

  const sendFeedback = (values, actions) => {
    const formData = { ...values };

    formData.full_name = transform(formData.full_name);
    formData.email = formData.email.toLowerCase();
    formData.comment = formData.comment.trim();

    sendArticleCommentForm(formData)
      .then((response) => {
        if (response.status === 200) {
          actions.resetForm({
            touched: {},
            submitCount: 0,
          });

          dispatch(showInfoMessage('article_comment_form'));
        }

        console.log(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <>
      <Formik
        initialValues={{
          full_name: '',
          email: '',
          comment: '',
        }}
        validationSchema={commentOnArticleSchema}
        onSubmit={sendFeedback}
      >
        {(formik) => (
          <Form className="article-comment-form">
            <div className="article-comment-form-fields">
              <OrdinaryInput
                label={t('form_fields:article_comment_form.full_name.label')}
                placeholder={t('form_fields:article_comment_form.full_name.placeholder')}
                name="full_name"
                type="text"
                maxLength={40}
              />
              <OrdinaryInput
                label={t('form_fields:article_comment_form.email.label')}
                placeholder={t('form_fields:article_comment_form.email.placeholder')}
                name="email"
                type="email"
              />
              <Textarea
                label={t('form_fields:article_comment_form.comment.label')}
                placeholder={t('form_fields:article_comment_form.comment.placeholder')}
                name="comment"
                rows={2}
                maxRows={7}
              />
            </div>
            <Submit
              priority="primary"
              isDisabled={formik.isSubmitting}
            >
              {t('buttons:send')}
            </Submit>
          </Form>
        )}
      </Formik>
      {
        isInfoMessageVisible && contentKey === 'article_comment_form' && (
          <InfoMessage>
            {t('info_messages:article_comment_form')}
          </InfoMessage>
        )
      }
    </>
  );
}
