/* eslint-disable react/prop-types */
import React from 'react';
import defaultCompanyLogo from '../../assets/images/logos/TPP/default-company-logo.svg';
import address from '../../assets/icons/info/address.svg';
import phoneGreen from '../../assets/icons/info/phone_green.svg';
import email from '../../assets/icons/info/email.svg';
import website from '../../assets/icons/info/website.svg';
import './CompanyItem.scss';

export default function CompanyItem({ data }) {
  return (
    <div className="company-list__item">
      <div className="company-list__cover">
        <img
          className="company-logo"
          src={data.logo ? data.logo.url : defaultCompanyLogo}
          alt={data.name}
        />
        <h2 className="heading-two">
          {data.name}
        </h2>
      </div>
      <div className="company-list__basic-info">
        {
          data.activity && (
            <p className="paragraph">
              {data.activity}
            </p>
          )
        }
        {
          data.address && (
            <div className="basic-info-item">
              <img
                className="info-icon"
                src={address}
                alt="address"
              />
              <p className="paragraph">
                {data.address}
              </p>
            </div>
          )
        }
        <div className="basic-info-item">
          <img
            className="info-icon"
            src={phoneGreen}
            alt="phoneGreen"
          />
          <p className="paragraph">
            {data.phone}
          </p>
        </div>
        <div className="basic-info-item">
          <img
            className="info-icon"
            src={email}
            alt="email"
          />
          <p className="paragraph">
            {data.email}
          </p>
        </div>
        {
          data.website && (
            <div className="basic-info-item">
              <img
                className="info-icon"
                src={website}
                alt="website"
              />
              <p className="paragraph">
                {data.website}
              </p>
            </div>
          )
        }
      </div>
    </div>
  );
}
