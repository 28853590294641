/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import servicesAPI from '../../../store/actions/servicesActions';
import Button from '../../ReusableElements/Buttons/Button/Button';
import CallOrderForm from '../../CallOrderForm/CallOrderForm';
import './CustomsAndBrokerageServices.scss';

export default function CustomsAndBrokerageServices() {
  const { t, i18n } = useTranslation(['service_pages', 'buttons']);
  const dispatch = useDispatch();
  const {
    serviceData,
    // isReady,
    // notification,
  } = useSelector((state) => state.services);

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'instant',
    });

    dispatch(servicesAPI());
  }, []);
  const ldJson = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Івано-Франківська торгово-промислова палата',
    url: 'https://cci.if.ua/',
    logo: 'https://cci.if.ua/static/media/logo_small.088e05e0446873d0c8dfb27546b82194.svg',
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: '+380342523347',
      contactType: 'customer service',
      areaServed: 'UA',
      availableLanguage: 'Ukrainian',
    },
    sameAs: [
      'https://www.facebook.com/cci.if.ua/',
      'https://www.instagram.com/if.cci.ua/',
    ],
  };
  console.log(serviceData);
  return (
    <section className="CustomsAndBrokerageServices">
      <Helmet>
        <title>
          Комплексні Митно-брокерські Послуги | Івано-Франківська ТПП
        </title>
        <meta
          name="description"
          content="Дізнайтеся про різноманітні послуги з митного оформлення,
          консультацій та допомоги у зберіганні товарів, які надає Івано-Франківська ТПП."
        />
        <script type="application/ld+json">
          {JSON.stringify(ldJson)}
        </script>
      </Helmet>
      <div className="wrapper">
        <div className="customs-and-brokerage-services-content">
          <div className="customs-and-brokerage-services-content-top-row">
            <a
              className="previous-page-link"
              href={
                i18n.languages[0] === 'en'
                  ? '/en/'
                  : '/'
              }
            >
              {t('previous_page_title')}
            </a>
            <div className="customs-and-brokerage-services-content-top-row__right-side">
              <h1 className="heading-one">
                {t('heading.customs_and_brokerage_services')}
              </h1>
              <p className="description">
                {t('description')}
              </p>
              <div className="types-of-service">
                <p className="paragraph">
                  Митно-брокерські послуги включають повне узгодження процесу перетину Вашими товарами кордону. Ми
                  надаємо широкий спектр послуг з митного оформлення – від професійних консультацій та підготовки
                  необхідної документації до вирішення питань про транспортування та зберігання вантажу.
                </p>
                <p className="paragraph paragraph_bold-font">
                  Митно-брокерські послуги Івано-Франківської торгово-промислової палати (ТПП):
                </p>
                <ul>
                  <li>
                    акредитація на митниці;
                  </li>
                  <li>
                    супровід та допомога в проходженні сертифікації товарів;
                  </li>
                  <li>
                    митне оформлення вантажів у режимах імпорт, експорт, транзит і тимчасового ввезення;
                  </li>
                  <li>
                    визначення коду товару за УКТЗЕД;
                  </li>
                  <li>
                    розрахунок усіх митних платежів;
                  </li>
                  <li>
                    консультація на всіх етапах ЗЕД;
                  </li>
                  <li>
                    кваліфікована допомога у підготовці товаросупровідних документів;
                  </li>
                  <li>
                    видача карнетів АТА.
                  </li>
                </ul>
              </div>
              <div className="list-of-required-documents">
                <p className="paragraph paragraph_bold-font">
                  Замовник разом із заявкою повинен надати оригінали документів або їх копії, засвідчені в
                  установленому порядку, а саме:
                </p>
                <ul>
                  <li>
                    контракт із усіма чинними на дату подання додатками, специфікаціями, додатковими угодами та іншими
                    документами, які є невід'ємними його частинами;
                  </li>
                  <li>
                    транспортні документи (залізнична накладна, міжнародна автомобільна накладна, авіаційна вантажна
                    накладна та коносамент);
                  </li>
                  <li>
                    розрахункові документи (рахунок-фактура (інвойс), специфікація, прас виробника тощо);
                  </li>
                  <li>
                    товаросупровідні документи (відвантажувальна специфікація, пакувальні листи, сертифікат якості
                    тощо);
                  </li>
                  <li>
                    нормативна документація на конкретний вид продукції;
                  </li>
                  <li>
                    технічна або технологічна документація;
                  </li>
                  <li>
                    свідоцтво про реєстрацію транспортного засобу;
                  </li>
                  <li>
                    договір на надання транспортних послуг (при потребі).
                  </li>
                </ul>
              </div>
              <div className="buttons">
                <Button
                  priority="primary"
                  link="#"
                  download
                >
                  {t('buttons:download_the_form')}
                </Button>
                <Button
                  priority="secondary"
                  link="#"
                  target="_blank"
                >
                  {t('buttons:view_the_sample')}
                </Button>
              </div>
            </div>
          </div>
          <h2 className="heading-two heading-two_form">
            {t('call_order_form_heading')}
          </h2>
          <p className="description">
            {t('call_order_form_description')}
          </p>
          <CallOrderForm />
        </div>
      </div>
    </section>
  );
}
