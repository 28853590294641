/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import './AdvertisingInfo.scss';
import { deleteAdvertising } from '../../../../requests/requests';

export default function AdvertisingInfo({
  data, onChangeView,
}) {
  useEffect(() => {}, []);

  const onDelete = () => {
    deleteAdvertising(data.id);
  };
  return (
    <tr className="AdvertisingInfo">
      <td>{data.id}</td>
      <td>{data.title}</td>
      <td>{data.description}</td>
      <td>{data.url}</td>
      <td><img src={data.image} alt="Add" width="150" height="150" /></td>
      <td><button type="button" className="admin_button" onClick={onChangeView}>Редагувати</button></td>
      <td><button type="button" className="admin_button" onClick={onDelete}>Видалити</button></td>
    </tr>
  );
}
