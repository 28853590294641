/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { updateCompanyProfile, companyProfileAPI } from '../../store/actions/companyProfileActions';
import { updateCompanyProfileData } from '../../requests/requests';
import { showInfoMessage } from '../../store/actions/modalWindowsActions';
import defaultCompanyLogo from '../../assets/images/logos/TPP/default-company-logo.svg';
import OrdinaryInput from '../ReusableElements/FormElemets/OrdinaryInput/OrdinaryInput';
import PhoneInputElement from '../ReusableElements/FormElemets/PhoneInputElement/PhoneInputElement';
import Submit from '../ReusableElements/Buttons/Submit/Submit';
import InfoMessage from '../ModalWindows/InfoMessage/InfoMessage';
import './CompanyProfileForm.scss';

export default function CompanyProfileForm() {
  const { t } = useTranslation(['form_error_messages', 'form_fields', 'buttons', 'info_messages', 'notifications']);
  const [minPhoneLengthLimit, setMinPhoneLengthLimit] = useState();
  const [requiredPhoneLength, setRequiredPhoneLength] = useState();
  const [currentDialCode, setCurrentDialCode] = useState();
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState();
  const [isNecessaryToCloseAllEditors, setIsNecessaryToCloseAllEditors] = useState(false);
  const dispatch = useDispatch();
  const {
    companyProfileData,
    isReady,
    notification,
  } = useSelector((state) => state.companyProfile);
  const {
    isInfoMessageVisible,
    contentKey,
  } = useSelector((state) => state.modalWindows.infoMessage);

  const inputNames = [
    'company_name',
    'edrpou_code',
    'kind_of_activity',
    'legal_address',
    'physical_address',
    'phone',
    'email',
    'website',
  ];

  const initialValues = () => {
    const initialData = {};

    inputNames.forEach((inputName) => {
      if (inputName === 'company_name') {
        initialData.company_name = companyProfileData.company_data.name;
      } else if (inputName === 'phone') {
        initialData.phone = companyProfileData.company_data.phone;

        if (!formattedPhoneNumber) {
          setFormattedPhoneNumber(companyProfileData.company_data.phone);
        }
      } else {
        initialData[inputName] = companyProfileData.company_data[inputName];
      }
    });

    return initialData;
  };

  const companyProfileSchema = Yup.object({
    company_name: Yup
      .string()
      .required(t('company_profile_form.company_name.required'))
      .matches(/^["“”„“«».,:()'/!?№+=*@a-zа-щєіїьюя\d\s–-]+$/i, t('company_profile_form.company_name.matches')),
    edrpou_code: Yup
      .string()
      .required(t('company_profile_form.edrpou_code.required'))
      .matches(/^[\d]+$/, t('company_profile_form.edrpou_code.matches'))
      .length(8, t('company_profile_form.edrpou_code.length')),
    kind_of_activity: Yup
      .string()
      .required(t('company_profile_form.kind_of_activity.required'))
      .matches(/^[.,:()'/a-zа-щєіїьюя\d\s–-]+$/i, t('company_profile_form.kind_of_activity.matches')),
    legal_address: Yup
      .string()
      .required(t('company_profile_form.legal_address.required'))
      .matches(/^[.,:()'/№a-zа-щєіїьюя\d\s–-]+$/i, t('company_profile_form.legal_address.matches')),
    physical_address: Yup
      .string()
      .required(t('company_profile_form.physical_address.required'))
      .matches(/^[.,:()'/№a-zа-щєіїьюя\d\s–-]+$/i, t('company_profile_form.physical_address.matches')),
    phone: minPhoneLengthLimit && requiredPhoneLength
      ? Yup
        .string()
        .min(minPhoneLengthLimit + 2, t('company_profile_form.phone.min'))
        .length(requiredPhoneLength + 1, t('company_profile_form.phone.length'))
      : null,
    email: Yup
      .string()
      .required(t('company_profile_form.email.required'))
      .email(t('company_profile_form.email.email')),
    website: Yup
      .string()
      .required(t('company_profile_form.website.required'))
      .url(t('company_profile_form.website.url')),
  });

  const saveCompanyProfileData = (values, actions) => {
    const {
      company_name,
      edrpou_code,
      kind_of_activity,
      legal_address,
      physical_address,
      phone,
      email,
      website,
    } = values;
    const formData = {
      company_data: {
        name: company_name.trim(),
        edrpou_code,
        kind_of_activity: kind_of_activity.trim(),
        legal_address: legal_address.trim(),
        physical_address: physical_address.trim(),
        phone,
        email,
        website: website.toLowerCase(),
      },
    };

    updateCompanyProfileData(companyProfileData.id, formData)
      .then((response) => {
        if (response.status === 200) {
          actions.resetForm({
            values,
            touched: {},
            submitCount: 0,
          });

          dispatch(updateCompanyProfile({ ...companyProfileData, ...formData }));
          dispatch(showInfoMessage('company_profile_form_success'));
          setIsNecessaryToCloseAllEditors(true);
        }

        console.log(response.data);
      })
      .catch((error) => {
        actions.resetForm({
          values,
          touched: {},
          submitCount: 0,
        });

        dispatch(updateCompanyProfile({ ...companyProfileData, ...formData }));
        dispatch(showInfoMessage('company_profile_form_failure'));
        setIsNecessaryToCloseAllEditors(true);

        console.log(error.message);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const setPhoneParameters = (minLengthLimit, requiredLength) => {
    setMinPhoneLengthLimit(minLengthLimit);
    setRequiredPhoneLength(requiredLength);
  };

  useEffect(() => {
    dispatch(companyProfileAPI());
  }, []);

  return (
    isReady
      ? (
        <>
          <Formik
            initialValues={initialValues()}
            validationSchema={companyProfileSchema}
            onSubmit={saveCompanyProfileData}
          >
            {(formik) => (
              <Form className="company-profile-form">
                <div className="company-profile-form-fields">
                  <div className="company-profile-form-fields-top-row">
                    <img
                      className="company-logo"
                      src={companyProfileData.logo ? companyProfileData.logo.url : defaultCompanyLogo}
                      alt={companyProfileData.company_data.name}
                    />
                    <div className="company-profile-form-fields-top-row__right-side">
                      <OrdinaryInput
                        label={t('form_fields:company_profile_form.company_name.label')}
                        placeholder={t('form_fields:company_profile_form.company_name.placeholder')}
                        name="company_name"
                        type="text"
                        source="company_profile"
                        isDisabled
                        isEditable
                        isNecessaryToCloseAllEditors={isNecessaryToCloseAllEditors}
                        setIsNecessaryToCloseAllEditors={setIsNecessaryToCloseAllEditors}
                      />
                      <OrdinaryInput
                        label={t('form_fields:company_profile_form.edrpou_code.label')}
                        placeholder={t('form_fields:company_profile_form.edrpou_code.placeholder')}
                        name="edrpou_code"
                        type="text"
                        maxLength={8}
                        source="company_profile"
                        isDisabled
                        isEditable
                        isNecessaryToCloseAllEditors={isNecessaryToCloseAllEditors}
                        setIsNecessaryToCloseAllEditors={setIsNecessaryToCloseAllEditors}
                      />
                      <OrdinaryInput
                        label={t('form_fields:company_profile_form.kind_of_activity.label')}
                        placeholder={t('form_fields:company_profile_form.kind_of_activity.placeholder')}
                        name="kind_of_activity"
                        type="text"
                        source="company_profile"
                        isDisabled
                        isEditable
                        isNecessaryToCloseAllEditors={isNecessaryToCloseAllEditors}
                        setIsNecessaryToCloseAllEditors={setIsNecessaryToCloseAllEditors}
                      />
                    </div>
                  </div>
                  <div className="company-profile-form-fields-bottom-row">
                    <OrdinaryInput
                      label={t('form_fields:company_profile_form.legal_address.label')}
                      placeholder={t('form_fields:company_profile_form.legal_address.placeholder')}
                      name="legal_address"
                      type="text"
                      source="company_profile"
                      isDisabled
                      isEditable
                      isNecessaryToCloseAllEditors={isNecessaryToCloseAllEditors}
                      setIsNecessaryToCloseAllEditors={setIsNecessaryToCloseAllEditors}
                    />
                    <OrdinaryInput
                      label={t('form_fields:company_profile_form.physical_address.label')}
                      placeholder={t('form_fields:company_profile_form.physical_address.placeholder')}
                      name="physical_address"
                      type="text"
                      source="company_profile"
                      isDisabled
                      isEditable
                      isNecessaryToCloseAllEditors={isNecessaryToCloseAllEditors}
                      setIsNecessaryToCloseAllEditors={setIsNecessaryToCloseAllEditors}
                    />
                    <PhoneInputElement
                      label={t('form_fields:company_profile_form.phone.label')}
                      placeholder={t('form_fields:company_profile_form.phone.placeholder')}
                      name="phone"
                      type="tel"
                      setPhoneParameters={setPhoneParameters}
                      currentDialCode={currentDialCode}
                      setCurrentDialCode={setCurrentDialCode}
                      formattedPhoneNumber={formattedPhoneNumber}
                      setFormattedPhoneNumber={setFormattedPhoneNumber}
                      source="company_profile"
                      isDisabled
                      isEditable
                      isNecessaryToCloseAllEditors={isNecessaryToCloseAllEditors}
                      setIsNecessaryToCloseAllEditors={setIsNecessaryToCloseAllEditors}
                    />
                    <OrdinaryInput
                      label={t('form_fields:company_profile_form.email.label')}
                      placeholder={t('form_fields:company_profile_form.email.placeholder')}
                      name="email"
                      type="email"
                      source="company_profile"
                      isDisabled
                      isNecessaryToCloseAllEditors={isNecessaryToCloseAllEditors}
                      setIsNecessaryToCloseAllEditors={setIsNecessaryToCloseAllEditors}
                    />
                    <OrdinaryInput
                      label={t('form_fields:company_profile_form.website.label')}
                      placeholder={t('form_fields:company_profile_form.website.placeholder')}
                      name="website"
                      type="url"
                      source="company_profile"
                      isDisabled
                      isEditable
                      isNecessaryToCloseAllEditors={isNecessaryToCloseAllEditors}
                      setIsNecessaryToCloseAllEditors={setIsNecessaryToCloseAllEditors}
                    />
                  </div>
                </div>
                <Submit
                  priority="primary"
                  isDisabled={!(formik.dirty && formik.isValid && !formik.isSubmitting)}
                >
                  {t('buttons:save')}
                </Submit>
              </Form>
            )}
          </Formik>
          {
            isInfoMessageVisible && contentKey.startsWith('company_profile_form') && (
              contentKey === 'company_profile_form_success'
                ? (
                  <InfoMessage>
                    {t('info_messages:company_profile_form_success')}
                  </InfoMessage>
                )
                : (
                  <InfoMessage>
                    {t('info_messages:company_profile_form_failure')}
                  </InfoMessage>
                )
            )
          }
        </>
      )
      : (
        <p className="notification">
          {t(`notifications:${notification}`, notification)}
        </p>
      )
  );
}
