import React, { useEffect } from 'react';
// import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Moment from 'react-moment';
import { eventsAPI } from '../../../store/actions/eventsActions';
import SeveralImagesSlider from '../../ReusableElements/SeveralImagesSlider/SeveralImagesSlider';
import calendarWhite from '../../../assets/icons/calendar_white.svg';
import Button from '../../ReusableElements/Buttons/Button/Button';
import 'moment/locale/uk';
import './Activities.scss';

export default function Activities() {
  const { t, i18n } = useTranslation(['landing_page', 'notifications', 'not_found_messages', 'buttons']);
  const dispatch = useDispatch();
  const {
    eventList,
    perPage,
    pageCount,
    currentPage,
    isReady,
    notification,
  } = useSelector((state) => state.events);

  const availableWindowWidth = document.documentElement.clientWidth;
  const breakpoints = [1441, 601];

  useEffect(() => {
    const header = document.querySelector('.Header');
    const activitiesAnchor = document.querySelector('#activities');

    activitiesAnchor.style.top = `-${header.offsetHeight}px`;

    dispatch(eventsAPI());
  }, []);

  return (
    <section className="Activities">
      <div id="activities" className="anchor" />
      <div className="wrapper">
        <div className="activities-content">
          <h2 className="heading-two">
            {t('activities.heading')}
          </h2>
          <p className="description">
            {t('activities.description')}
          </p>
          {
            perPage && pageCount !== null && currentPage && isReady
              ? (
                <div className="activities-slider-block">
                  <SeveralImagesSlider
                    origin="activities"
                    isArrows={availableWindowWidth > 834}
                    isDots={availableWindowWidth <= 834}
                    breakpoints={breakpoints}
                  >
                    {
                      eventList[1] && eventList[1].map((eventItem) => (
                        <Link
                          key={uuidv4()}
                          to={
                            i18n.languages[0] === 'en'
                              ? `/activities/${eventItem.id}/en/`
                              : `/activities/${eventItem.id}/`
                          }
                        >
                          <div className="activity-item">
                            <div className="image-dimming" />
                            <img
                              className="activity-image"
                              src={eventItem.cover?.url}
                              alt={eventItem.title}
                              loading="lazy"
                            />
                            <div className="activity-title">
                              <img
                                className="cover-icon"
                                src={calendarWhite}
                                alt="calendarWhite"
                              />
                              <h3 className="heading-three">
                                {eventItem.title}
                              </h3>
                            </div>
                            <Moment
                              className="event-date"
                              format="LL"
                              locale={
                                i18n.languages[0] === 'en'
                                  ? 'en'
                                  : 'uk'
                              }
                            >
                              {eventItem.event_date}
                            </Moment>
                          </div>
                        </Link>
                      ))
                    }
                  </SeveralImagesSlider>
                </div>
              )
              : (
                <p className="notification">
                  {t(`notifications:${notification}`, notification)}
                </p>
              )
          }
          {
            pageCount === 0 && (
              <p className="activities-not-found">
                {t('activities_not_found')}
              </p>
            )
          }
          <Button
            priority="primary"
            link={
              i18n.languages[0] === 'en'
                ? '/activities/en/'
                : '/activities/'
            }
          >
            {t('buttons:learn_more')}
          </Button>
        </div>
      </div>
    </section>
  );
}
