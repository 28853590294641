/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import './ArticleInfo.scss';
import Button from '../Button/Button';
import { changeArticleState } from '../../../requests/requests';

export default function ArticleInfo({
  id, title, description, status, author,
}) {
  useEffect(() => {}, []);

  const handle_click = (_id) => {
    changeArticleState(_id);
  };

  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);
  const [editedDescription, setEditedDescription] = useState(description);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    // Perform save operation here
    setIsEditing(false);
  };

  return (
    <tr className="ArticleInfo">
      <td className="article-info__item">{id}</td>
      <td className="article-info__item">
        {isEditing ? (
          <input
            type="text"
            value={editedTitle}
            onChange={(e) => setEditedTitle(e.target.value)}
          />
        ) : (
          title
        )}
      </td>
      <td className="article-info__item">
        {isEditing ? (
          <textarea
            value={editedDescription}
            onChange={(e) => setEditedDescription(e.target.value)}
          />
        ) : (
          description
        )}
      </td>
      <td className="article-info__item">
        temp
      </td>
      <td className="article-info__item">
        <div>
          <p>{status}</p>
          {isEditing ? (
            <Button name="save" handle_click={handleSave} className="admin_button" />
          ) : (
            <Button
              name={status === 'unpublished' ? 'publish' : 'unpublish'}
              handle_click={() => handle_click(id)}
              className="admin_button"
            />
          )}
          {!isEditing && (
            <Button name="edit" handle_click={handleEdit} className="admin_button" />
          )}
        </div>
      </td>
      <td className="article-info__item">{author}</td>
    </tr>
  );
}
