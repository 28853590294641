/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import Button from '../Button/Button';
import './Menu.scss';

function RenderMenu(items, onClick, level = 0) {
  return (
    <>
      {
        items.map((item) => (
          <React.Fragment key={item.id + item.name}>
            <div>
              <Button
                handle_click={() => onClick(item.id, item.name, level)}
                name={item.name}
                width={180 - (level * 20)}
                clicked={item.open}
              />
            </div>
            {
              item.subitems.length > 0
              && item.open
              && RenderMenu(item.subitems, onClick, level + 1)
            }
          </React.Fragment>
        ))
      }
    </>
  );
}

export default function Menu({ menuItems, setMenuItems }) {
  const toggleMenuItem = (id, name, level) => {
    const copyItems = JSON.parse(JSON.stringify(menuItems));

    const closeSubItems = (items) => {
      for (let i = 0; i < items.length; i++) {
        items[i].open = false;
        closeSubItems(items[i].subitems);
      }
    };

    const findAndChange = (items, currentLevel = 0) => {
      for (let i = 0; i < items.length; i++) {
        if (items[i].id === id && items[i].name === name) {
          closeSubItems(items[i].subitems.length > 0 ? items[i].subitems : items);
          items[i].open = !items[i].open;
          break;
        }
        if (items[i].subitems.length > 0) {
          findAndChange(items[i].subitems, currentLevel + 1);
        }
        if (currentLevel >= level) {
          items[i].open = false;
        }
      }
    };

    findAndChange(copyItems);
    setMenuItems(copyItems);
  };

  return (
    <section className="menu">
      {RenderMenu(menuItems, toggleMenuItem)}
    </section>
  );
}
