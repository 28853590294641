import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: window.location.origin === 'http://localhost:3000'
    ? 'http://localhost:3001/api/v1'
    : `${window.location.origin}/api/v1`,
  headers: {
    Authorization: localStorage.getItem('AUTH_TOKEN'),
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    console.log('config:\n', config);

    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => {
    console.log('response:\n', response);

    return response;
  },
  (error) => Promise.reject(error),
);

export default axiosInstance;
