/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import calendarWhite from '../../assets/icons/calendar_white.svg';
import calendarGreen from '../../assets/icons/info/calendar_green.svg';
import person from '../../assets/icons/info/person.svg';
import phoneGreen from '../../assets/icons/info/phone_green.svg';
import email from '../../assets/icons/info/email.svg';
import Button from '../ReusableElements/Buttons/Button/Button';
import 'moment/locale/uk';
import './ActivityItem.scss';

export default function ActivityItem({ data }) {
  const { t, i18n } = useTranslation(['activities_page', 'buttons']);

  return (
    <div className="activity-list__item">
      <div className="activity-list__cover">
        <div className="image-dimming" />
        <img
          className="activity-image"
          src={data.cover?.url}
          alt={data.title}
        />
        <div className="activity-title">
          <img
            className="cover-icon"
            src={calendarWhite}
            alt="calendarWhite"
          />
          <h2 className="heading-two">
            {data.title}
          </h2>
        </div>
        <Moment
          className="event-date"
          format="LLL"
          locale={
            i18n.languages[0] === 'en'
              ? 'en'
              : 'uk'
          }
        >
          {data.event_date}
        </Moment>
      </div>
      <div className="activity-list__basic-info">
        <h3 className="heading-three">
          {data.title}
        </h3>
        <div className="basic-info-item">
          <img
            className="info-icon"
            src={calendarGreen}
            alt="calendarGreen"
          />
          <p className="paragraph">
            {`${t('basic_info.event_date')}: `}
            <Moment format="YYYY-MM-DD">
              {data.event_date}
            </Moment>
          </p>
        </div>
        {
          data.coordinator && (
            <div className="basic-info-item">
              <img
                className="info-icon"
                src={person}
                alt="person"
              />
              <p className="paragraph">
                {`${t('basic_info.coordinator')}: ${data.coordinator}`}
              </p>
            </div>
          )
        }
        {
          data.phone && (
            <div className="basic-info-item">
              <img
                className="info-icon"
                src={phoneGreen}
                alt="phoneGreen"
              />
              <p className="paragraph">
                {`${t('basic_info.phone')}: ${data.phone}`}
              </p>
            </div>
          )
        }
        {
          data.email && (
            <div className="basic-info-item">
              <img
                className="info-icon"
                src={email}
                alt="email"
              />
              <p className="paragraph">
                {`${t('basic_info.email')}: ${data.email}`}
              </p>
            </div>
          )
        }
        <Button
          priority="primary"
          link={
            i18n.languages[0] === 'en'
              ? `/activities/${data.id}/en/`
              : `/activities/${data.id}/`
          }
        >
          {t('buttons:in_detail')}
        </Button>
      </div>
    </div>
  );
}
