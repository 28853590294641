/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import {
  showHamburgerMenu,
  hideHamburgerMenu,
  showMainDropdownList,
  hideMainDropdownList,
  showDocumentsDropdownList,
  hideDocumentsDropdownList,
  showProposalsForCCIDropdownList,
  hideProposalsForCCIDropdownList,
  showSearchForm,
  hideSearchForm,
} from '../../../store/actions/menusActions';
import { userLogOut } from '../../../requests/requests';
import { showLogIn } from '../../../store/actions/modalWindowsActions';
import logoSmall from '../../../assets/images/logos/TPP/logo_small.svg';
import chevron from '../../../assets/icons/chevron.svg';
import search from '../../../assets/icons/actions/search.svg';
import SearchForm from '../SearchForm/SearchForm';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import LogIn from '../../ModalWindows/LogIn/LogIn';
import './Header.scss';

export default function Header() {
  const { t, i18n } = useTranslation('header');
  const dispatch = useDispatch();
  const {
    isHamburgerMenuActive,
    isMainDropdownListVisible,
    isDocumentsDropdownListVisible,
    isProposalsForCCIDropdownListVisible,
    isSearchFormActive,
  } = useSelector((state) => state.menus);
  const { isLogInVisible } = useSelector((state) => state.modalWindows);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // const token = localStorage.getItem('AUTH_TOKEN');
  const availableWindowWidth = document.documentElement.clientWidth;

  const toggleHamburgerMenu = () => {
    if (isMainDropdownListVisible) {
      dispatch(hideMainDropdownList());
    }

    if (isDocumentsDropdownListVisible) {
      dispatch(hideDocumentsDropdownList());
    }

    if (isProposalsForCCIDropdownListVisible) {
      dispatch(hideProposalsForCCIDropdownList());
    }

    if (isHamburgerMenuActive) {
      dispatch(hideHamburgerMenu());
    } else {
      dispatch(showHamburgerMenu());
    }
  };

  const toggleMainDropdownList = () => {
    if (isDocumentsDropdownListVisible) {
      dispatch(hideDocumentsDropdownList());
    }

    if (isProposalsForCCIDropdownListVisible) {
      dispatch(hideProposalsForCCIDropdownList());
    }

    if (isMainDropdownListVisible) {
      dispatch(hideMainDropdownList());
    } else {
      dispatch(showMainDropdownList());
    }
  };

  const toggleDocumentsDropdownList = () => {
    if (isMainDropdownListVisible) {
      dispatch(hideMainDropdownList());
    }

    if (isProposalsForCCIDropdownListVisible) {
      dispatch(hideProposalsForCCIDropdownList());
    }

    if (isDocumentsDropdownListVisible) {
      dispatch(hideDocumentsDropdownList());
    } else {
      dispatch(showDocumentsDropdownList());
    }
  };

  const toggleProposalsForCCIDropdownList = () => {
    if (isMainDropdownListVisible) {
      dispatch(hideMainDropdownList());
    }

    if (isDocumentsDropdownListVisible) {
      dispatch(hideDocumentsDropdownList());
    }

    if (isProposalsForCCIDropdownListVisible) {
      dispatch(hideProposalsForCCIDropdownList());
    } else {
      dispatch(showProposalsForCCIDropdownList());
    }
  };

  const hideNavigationLists = () => {
    if (isMainDropdownListVisible) {
      dispatch(hideMainDropdownList());
    }

    if (isDocumentsDropdownListVisible) {
      dispatch(hideDocumentsDropdownList());
    }

    if (isProposalsForCCIDropdownListVisible) {
      dispatch(hideProposalsForCCIDropdownList());
    }

    dispatch(hideHamburgerMenu());
  };

  const logOut = () => {
    userLogOut()
      .then((response) => {
        if (response.status === 200) {
          localStorage.removeItem('AUTH_TOKEN');
          // navigate(
          //   i18n.languages[0] === 'en'
          //     ? '/en/'
          //     : '/',
          // );
          // dispatch(hideHamburgerMenu());
        }

        console.log(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => {
        navigate(
          i18n.languages[0] === 'en'
            ? '/en/'
            : '/',
        );
        dispatch(hideHamburgerMenu());
      });
  };

  const showLogInForm = () => {
    dispatch(showLogIn());
    dispatch(hideHamburgerMenu());
  };

  const toggleSearchForm = () => {
    if (isSearchFormActive) {
      dispatch(hideSearchForm());
    } else {
      dispatch(showSearchForm());
    }
  };

  const wrapper = (
    <div className="wrapper">
      <div className="header-content">
        <div
          className={classNames('hamburger-menu', { active: isHamburgerMenuActive })}
          aria-hidden="true"
          onClick={toggleHamburgerMenu}
        >
          <span />
          <span />
          <span />
        </div>
        <a
          href={
            i18n.languages[0] === 'en'
              ? '/en/'
              : '/'
          }
        >
          <img
            className="logo-small"
            src={logoSmall}
            alt="logoSmall"
          />
        </a>
        <nav className={classNames('navigation', { active: isHamburgerMenuActive })}>
          <ul className="list">
            {
              // token
              pathname.startsWith('/personal_account/')
                ? (
                  <>
                    <div
                      className={classNames('main-dropdown-list', { active: isMainDropdownListVisible })}
                      onMouseEnter={
                        availableWindowWidth > 834
                          ? () => dispatch(showMainDropdownList())
                          : null
                      }
                      onMouseLeave={
                        availableWindowWidth > 834
                          ? () => dispatch(hideMainDropdownList())
                          : null
                      }
                      onClick={
                        availableWindowWidth <= 834
                          ? () => toggleMainDropdownList()
                          : null
                      }
                    >
                      <button
                        className="dropdown-button"
                        type="button"
                      >
                        <span>
                          {t('personal_account.main')}
                        </span>
                        <img
                          src={chevron}
                          alt="chevron"
                        />
                      </button>
                      {
                        isMainDropdownListVisible && (
                        <ul>
                          <li className="main-dropdown-list__item">
                            <Link
                              className="main-dropdown-list__link"
                              to={
                                i18n.languages[0] === 'en'
                                  ? '/personal_account/company_profile/en/'
                                  : '/personal_account/company_profile/'
                              }
                              onClick={() => hideNavigationLists()}
                            >
                              {t('personal_account.main_dropdown_list.my_account')}
                            </Link>
                          </li>
                          <li className="main-dropdown-list__item">
                            <a
                              className="main-dropdown-list__link"
                              href={
                                i18n.languages[0] === 'en'
                                  ? '/en/#news-events'
                                  : '/#news-events'
                              }
                              onClick={() => hideNavigationLists()}
                            >
                              {t('personal_account.main_dropdown_list.news_events')}
                            </a>
                          </li>
                          <li className="main-dropdown-list__item">
                            <a
                              className="main-dropdown-list__link"
                              href={
                                i18n.languages[0] === 'en'
                                  ? '/en/#services'
                                  : '/#services'
                              }
                              onClick={() => hideNavigationLists()}
                            >
                              {t('personal_account.main_dropdown_list.services')}
                            </a>
                          </li>
                          <li className="main-dropdown-list__item">
                            <a
                              className="main-dropdown-list__link"
                              href={
                                i18n.languages[0] === 'en'
                                  ? '/en/#activities'
                                  : '/#activities'
                              }
                              onClick={() => hideNavigationLists()}
                            >
                              {t('personal_account.main_dropdown_list.activities')}
                            </a>
                          </li>
                          <li className="main-dropdown-list__item">
                            <a
                              className="main-dropdown-list__link"
                              href={
                                i18n.languages[0] === 'en'
                                  ? '/en/#about-us'
                                  : '/#about-us'
                              }
                              onClick={() => hideNavigationLists()}
                            >
                              {t('personal_account.main_dropdown_list.about_us')}
                            </a>
                          </li>
                          <li className="main-dropdown-list__item">
                            <a
                              className="main-dropdown-list__link"
                              href={
                                i18n.languages[0] === 'en'
                                  ? '/en/#forms'
                                  : '/#forms'
                              }
                              onClick={() => hideNavigationLists()}
                            >
                              {t('personal_account.main_dropdown_list.forms')}
                            </a>
                          </li>
                        </ul>
                        )
                      }
                    </div>
                    <div
                      className={classNames('documents-dropdown-list', { active: isDocumentsDropdownListVisible })}
                      onMouseEnter={
                        availableWindowWidth > 834
                          ? () => dispatch(showDocumentsDropdownList())
                          : null
                      }
                      onMouseLeave={
                        availableWindowWidth > 834
                          ? () => dispatch(hideDocumentsDropdownList())
                          : null
                      }
                      onClick={
                        availableWindowWidth <= 834
                          ? () => toggleDocumentsDropdownList()
                          : null
                      }
                    >
                      <button
                        className="dropdown-button"
                        type="button"
                      >
                        <span>
                          {t('personal_account.documents')}
                        </span>
                        <img
                          src={chevron}
                          alt="chevron"
                        />
                      </button>
                      {
                        isDocumentsDropdownListVisible && (
                        <ul>
                          <li className="documents-dropdown-list__item">
                            <Link
                              className="documents-dropdown-list__link"
                              to={
                                i18n.languages[0] === 'en'
                                  ? '/personal_account/cci_documents/en/'
                                  : '/personal_account/cci_documents/'
                              }
                              onClick={() => hideNavigationLists()}
                            >
                              {t('personal_account.documents_dropdown_list.CCI_documents')}
                            </Link>
                          </li>
                          <li className="documents-dropdown-list__item">
                            <Link
                              className="documents-dropdown-list__link"
                              to={
                                i18n.languages[0] === 'en'
                                  ? '/personal_account/client_documents/en/'
                                  : '/personal_account/client_documents/'
                              }
                              onClick={() => hideNavigationLists()}
                            >
                              {t('personal_account.documents_dropdown_list.my_documents')}
                            </Link>
                          </li>
                        </ul>
                        )
                      }
                    </div>
                    <li className="list__item">
                      <Link
                        className="list__link"
                        to={
                          i18n.languages[0] === 'en'
                            ? '/personal_account/service_order/en/'
                            : '/personal_account/service_order/'
                        }
                        onClick={() => dispatch(hideHamburgerMenu())}
                      >
                        {t('personal_account.order_a_service')}
                      </Link>
                    </li>
                    <div
                      className={classNames('proposals-for-CCI-dropdown-list', { active: isProposalsForCCIDropdownListVisible })}
                      onMouseEnter={
                        availableWindowWidth > 834
                          ? () => dispatch(showProposalsForCCIDropdownList())
                          : null
                      }
                      onMouseLeave={
                        availableWindowWidth > 834
                          ? () => dispatch(hideProposalsForCCIDropdownList())
                          : null
                      }
                      onClick={
                        availableWindowWidth <= 834
                          ? () => toggleProposalsForCCIDropdownList()
                          : null
                      }
                    >
                      <button
                        className="dropdown-button"
                        type="button"
                      >
                        <span>
                          {t('personal_account.proposals_for_CCI')}
                        </span>
                        <img
                          src={chevron}
                          alt="chevron"
                        />
                      </button>
                      {
                        isProposalsForCCIDropdownListVisible && (
                        <ul>
                          <li className="proposals-for-CCI-dropdown-list__item">
                            <Link
                              className="proposals-for-CCI-dropdown-list__link"
                              to={
                                i18n.languages[0] === 'en'
                                  ? '/personal_account/proposal/en/'
                                  : '/personal_account/proposal/'
                              }
                              onClick={() => hideNavigationLists()}
                            >
                              {t('personal_account.proposals_for_CCI_dropdown_list.make_a_proposal')}
                            </Link>
                          </li>
                          <li className="proposals-for-CCI-dropdown-list__item">
                            <Link
                              className="proposals-for-CCI-dropdown-list__link"
                              to={
                                i18n.languages[0] === 'en'
                                  ? '/personal_account/initiative/en/'
                                  : '/personal_account/initiative/'
                              }
                              onClick={() => hideNavigationLists()}
                            >
                              {t('personal_account.proposals_for_CCI_dropdown_list.propose_an_initiative')}
                            </Link>
                          </li>
                        </ul>
                        )
                      }
                    </div>
                  </>
                )
                : (
                  <>
                    <li className="list__item">
                      <a
                        className="list__link"
                        href={
                          i18n.languages[0] === 'en'
                            ? '/en/#main'
                            : '/#main'
                        }
                        onClick={() => dispatch(hideHamburgerMenu())}
                      >
                        {t('main')}
                      </a>
                    </li>
                    <li className="list__item">
                      <a
                        className="list__link"
                        href={
                          i18n.languages[0] === 'en'
                            ? '/en/#news-events'
                            : '/#news-events'
                        }
                        onClick={() => dispatch(hideHamburgerMenu())}
                      >
                        {t('news_events')}
                      </a>
                    </li>
                    <li className="list__item">
                      <a
                        className="list__link"
                        href={
                          i18n.languages[0] === 'en'
                            ? '/en/#services'
                            : '/#services'
                        }
                        onClick={() => dispatch(hideHamburgerMenu())}
                      >
                        {t('services')}
                      </a>
                    </li>
                    <li className="list__item">
                      <a
                        className="list__link"
                        href={
                          i18n.languages[0] === 'en'
                            ? '/en/#activities'
                            : '/#activities'
                        }
                        onClick={() => dispatch(hideHamburgerMenu())}
                      >
                        {t('activities')}
                      </a>
                    </li>
                    <li className="list__item">
                      <a
                        className="list__link"
                        href={
                          i18n.languages[0] === 'en'
                            ? '/en/#about-us'
                            : '/#about-us'
                        }
                        onClick={() => dispatch(hideHamburgerMenu())}
                      >
                        {t('about_us')}
                      </a>
                    </li>
                    <li className="list__item">
                      <a
                        className="list__link"
                        href={
                          i18n.languages[0] === 'en'
                            ? '/en/#forms'
                            : '/#forms'
                        }
                        onClick={() => dispatch(hideHamburgerMenu())}
                      >
                        {t('forms')}
                      </a>
                    </li>
                  </>
                )
            }
            <li className="list__item">
              <span
                className={
                  // token
                  pathname.startsWith('/personal_account/')
                    ? 'list__log-out'
                    : 'list__log-in'
                }
                aria-hidden="true"
                onClick={
                  // token
                  pathname.startsWith('/personal_account/')
                    ? logOut
                    : showLogInForm
                }
              >
                {
                  // token
                  pathname.startsWith('/personal_account/')
                    ? t('log_out')
                    : t('log_in')
                }
              </span>
            </li>
          </ul>
        </nav>
        <div className="folding-screen" />
        <button
          className="search"
          type="button"
          onClick={toggleSearchForm}
        >
          <img
            src={search}
            alt="search"
          />
        </button>
        <SearchForm
          className={classNames('search-form', { active: isSearchFormActive })}
          hideSearchForm={() => dispatch(hideSearchForm())}
        />
        <LanguageSwitcher />
      </div>
    </div>
  );

  return (
    <>
      <header className="Header fixed">
        {wrapper}
      </header>
      <header className="Header hidden">
        {wrapper}
      </header>
      {
        isLogInVisible && <LogIn />
      }
    </>
  );
}
