import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import vipCompaniesAPI from '../../../store/actions/vipCompaniesActions';
import VIPCompanyList from '../VIPCompanyList/VIPCompanyList';
import './OurPartners.scss';

export default function OurPartners() {
  const { t } = useTranslation(['landing_page', 'notifications']);
  const dispatch = useDispatch();
  const {
    vipCompanyData,
    isReady,
    notification,
  } = useSelector((state) => state.vipCompanies);

  useEffect(() => {
    dispatch(vipCompaniesAPI());
  }, []);

  return (
    <section className="OurPartners">
      <div className="wrapper">
        <div className="our-partners-content">
          <h2 className="heading-two">
            {t('our_partners.heading')}
          </h2>
          {
            isReady
              ? <VIPCompanyList vipCompanyData={vipCompanyData} />
              : (
                <p className="notification">
                  {t(`notifications:${notification}`, notification)}
                </p>
              )
          }
        </div>
      </div>
    </section>
  );
}
