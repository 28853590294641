/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { hideLogIn } from '../../../store/actions/modalWindowsActions';
import { userLogIn } from '../../../requests/requests';
import closeWhite from '../../../assets/icons/actions/close_white.svg';
import OrdinaryInput from '../../ReusableElements/FormElemets/OrdinaryInput/OrdinaryInput';
import Submit from '../../ReusableElements/Buttons/Submit/Submit';
import './LogIn.scss';

export default function LogIn() {
  const { t, i18n } = useTranslation(['form_error_messages', 'form_fields', 'buttons']);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const closeModalWindow = () => {
    dispatch(hideLogIn());
  };

  const logInSchema = Yup.object({
    email: Yup
      .string()
      .required(t('log_in.email.required'))
      .email(t('log_in.email.email')),
    current_password: Yup
      .string()
      .required(t('log_in.current_password.required'))
      .min(8, t('log_in.current_password.min'))
      .max(25, t('log_in.current_password.max')),
  });

  const submitLogIn = (values, actions) => {
    const {
      email,
      current_password,
    } = values;
    const formData = {
      email: email.toLowerCase(),
      password: current_password,
    };

    userLogIn(formData)
      .then((response) => {
        if (response.status === 200) {
          closeModalWindow();
          localStorage.setItem('AUTH_TOKEN', response.headers.authorization);
          navigate(
            i18n.languages[0] === 'en'
              ? '/personal_account/company_profile/en/'
              : '/personal_account/company_profile/',
          );
        }

        console.log(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  useEffect(() => {
    const availableWindowWidth = document.documentElement.clientWidth;
    const container = document.querySelector('.log-in .container');
    const modalWindow = document.querySelector('.log-in .modal-window');

    if (availableWindowWidth > 601 && container.offsetHeight < modalWindow.offsetHeight + 12) {
      container.style.alignItems = 'flex-start';
      modalWindow.style.marginTop = '12px';
    } else {
      container.style.alignItems = '';
      modalWindow.style.marginTop = '';
    }
  }, []);

  return (
    <div className="log-in">
      <div className="container">
        <div className="modal-window">
          <h1 className="heading-one">
            {t('form_fields:log_in.heading')}
          </h1>
          <p className="description">
            {t('form_fields:log_in.description')}
          </p>
          <button
            className="close"
            type="button"
            onClick={closeModalWindow}
          >
            <img
              src={closeWhite}
              alt="closeWhite"
            />
          </button>
          <Formik
            initialValues={{
              email: '',
              current_password: '',
            }}
            validationSchema={logInSchema}
            onSubmit={submitLogIn}
          >
            {(formik) => (
              <Form className="log-in-form">
                <div className="log-in-form-fields">
                  <OrdinaryInput
                    label={t('form_fields:log_in.fields.email.label')}
                    placeholder={t('form_fields:log_in.fields.email.placeholder')}
                    name="email"
                    type="email"
                  />
                  <OrdinaryInput
                    label={t('form_fields:log_in.fields.current_password.label')}
                    placeholder={t('form_fields:log_in.fields.current_password.placeholder')}
                    name="current_password"
                  />
                </div>
                <Submit
                  priority="primary"
                  isDisabled={formik.isSubmitting}
                >
                  {t('buttons:log_in')}
                </Submit>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
