/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useField, ErrorMessage } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import openEye from '../../../../assets/icons/open-eye.svg';
import closedEye from '../../../../assets/icons/closed-eye.svg';
import edit from '../../../../assets/icons/actions/edit.svg';
import closeGreen from '../../../../assets/icons/actions/close_green.svg';
import './OrdinaryInput.scss';

export default function OrdinaryInput({
  label,
  name,
  source,
  isDisabled = false,
  isEditable = false,
  isNecessaryToCloseAllEditors,
  setIsNecessaryToCloseAllEditors,
  ...props
}) {
  const [isInputFieldDisabled, setIsInputFieldDisabled] = useState(isDisabled);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [field, meta, helpers] = useField(name);

  const htmlFor = `${name}-${uuidv4()}`;
  const className = classNames({ invalid: meta.touched && meta.error });

  const changeHandler = (e) => {
    field.onChange(e);
  };

  const toggleActivity = () => {
    if (isInputFieldDisabled) {
      setIsInputFieldDisabled(false);
    } else {
      helpers.setValue(meta.initialValue);
      setIsInputFieldDisabled(true);
    }
  };

  let InputField;

  if (name === 'new_password' || name === 'new_password_confirmation' || name === 'current_password') {
    if (isPasswordVisible) {
      InputField = (
        <div className="password-container">
          <input
            id={htmlFor}
            className={className}
            type="text"
            autoComplete="off"
            disabled={isInputFieldDisabled}
            {...field}
            {...props}
            onChange={changeHandler}
          />
          <img
            src={openEye}
            alt="openEye"
            aria-hidden="true"
            onClick={() => setIsPasswordVisible(false)}
          />
        </div>
      );
    } else {
      InputField = (
        <div className="password-container">
          <input
            id={htmlFor}
            className={className}
            type="password"
            autoComplete="off"
            disabled={isInputFieldDisabled}
            {...field}
            {...props}
            onChange={changeHandler}
          />
          <img
            src={closedEye}
            alt="closedEye"
            aria-hidden="true"
            onClick={() => setIsPasswordVisible(true)}
          />
        </div>
      );
    }
  } else {
    InputField = (
      <input
        id={htmlFor}
        className={className}
        autoComplete="off"
        disabled={isInputFieldDisabled}
        {...field}
        {...props}
        onChange={changeHandler}
      />
    );
  }

  useEffect(() => {
    if (isNecessaryToCloseAllEditors) {
      setIsNecessaryToCloseAllEditors(false);
      setIsInputFieldDisabled(true);
    }
  }, [isNecessaryToCloseAllEditors]);

  return (
    <div className="ordinary-input-wrapper">
      {
        name === 'search'
          ? InputField
          : (
            <>
              <div className="title">
                <label htmlFor={htmlFor}>
                  {label}
                </label>
                {
                  isEditable && (
                    isInputFieldDisabled
                      ? (
                        <img
                          src={edit}
                          alt="edit"
                          aria-hidden="true"
                          onClick={toggleActivity}
                        />
                      )
                      : (
                        <img
                          src={closeGreen}
                          alt="closeGreen"
                          aria-hidden="true"
                          onClick={toggleActivity}
                        />
                      )
                  )
                }
              </div>
              {InputField}
              <ErrorMessage
                className="warning"
                component="span"
                name={name}
              />
            </>
          )
      }
    </div>
  );
}
