/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useRouteError, isRouteErrorResponse } from 'react-router-dom';
import {
  hideHamburgerMenu,
  hideMainDropdownList,
  hideDocumentsDropdownList,
  hideProposalsForCCIDropdownList,
  hideSearchForm,
  hideLanguageDropdownList,
} from '../../store/actions/menusActions';
import { showLogIn } from '../../store/actions/modalWindowsActions';
import Header from '../LandingPage/Header/Header';
import notFound from '../../assets/images/not-found.svg';
import Button from '../ReusableElements/Buttons/Button/Button';
import Footer from '../LandingPage/Footer/Footer';
import './ErrorPage.scss';

export default function ErrorPage() {
  const { t, i18n } = useTranslation(['error_page', 'buttons']);
  const dispatch = useDispatch();
  const {
    isHamburgerMenuActive,
    isMainDropdownListVisible,
    isDocumentsDropdownListVisible,
    isProposalsForCCIDropdownListVisible,
    isSearchFormActive,
    isLanguageDropdownListVisible,
  } = useSelector((state) => state.menus);
  const navigate = useNavigate();
  const error = useRouteError();

  const token = localStorage.getItem('AUTH_TOKEN');

  const clickHandler = () => {
    if (isHamburgerMenuActive) {
      dispatch(hideHamburgerMenu());
    }

    if (isMainDropdownListVisible) {
      dispatch(hideMainDropdownList());
    }

    if (isDocumentsDropdownListVisible) {
      dispatch(hideDocumentsDropdownList());
    }

    if (isProposalsForCCIDropdownListVisible) {
      dispatch(hideProposalsForCCIDropdownList());
    }

    if (isSearchFormActive) {
      dispatch(hideSearchForm());
    }

    if (isLanguageDropdownListVisible) {
      dispatch(hideLanguageDropdownList());
    }
  };

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'instant',
    });
  }, []);

  useEffect(() => {
    const header = document.querySelector('.Header.fixed');
    const errorPage = document.querySelector('.ErrorPage');
    const footer = document.querySelector('.Footer');
    const minimumRequiredHeight = document.documentElement.clientHeight - (header.offsetHeight + footer.offsetHeight);

    errorPage.style.minHeight = `${minimumRequiredHeight}px`;
  }, []);

  useEffect(() => {
    const {
      protocol,
      host,
      pathname,
      search,
      hash,
      href,
    } = window.location;

    let finalProtocol;
    let finalHost;
    let finalPathname;

    if (!host.endsWith('localhost:3000') && protocol === 'http:') {
      finalProtocol = 'https:';
    } else {
      finalProtocol = protocol;
    }

    if (host.startsWith('www.')) {
      finalHost = host.slice(4);
    } else {
      finalHost = host;
    }

    if (pathname.endsWith('/')) {
      finalPathname = pathname;
    } else {
      finalPathname = `${pathname}/`;
    }

    if (i18n.languages[0] === 'en' && !pathname.endsWith('en/')) {
      finalPathname = `${finalPathname}en/`;
    } else if (i18n.languages[0] === 'uk' && pathname.endsWith('en/')) {
      finalPathname = finalPathname.slice(0, finalPathname.length - 3);
    }

    const finalHref = `${finalProtocol}//${finalHost}${finalPathname}${search}${hash}`;

    if (finalHref !== href) {
      window.location.assign(finalHref);
    }

    // if (token) {
    //   if (finalHref !== href) {
    //     window.location.assign(finalHref);
    //   }
    // } else if (pathname.startsWith('/personal_account/')) {
    //   navigate(
    //     i18n.languages[0] === 'en'
    //       ? '/en/'
    //       : '/',
    //   );
    //   dispatch(showLogIn());
    // } else if (finalHref !== href) {
    //   window.location.assign(finalHref);
    // }
  }, [i18n.languages[0]]);

  return (
    <div className="App">
      <Helmet>
        <meta name="errorpage" content="true" />
        <meta name="errortype" content="404 - Not Found" />
        <meta name="prerender-status-code" content="404" />
      </Helmet>
      <Header />
      <main
        className="main"
        aria-hidden="true"
        onClick={clickHandler}
      >
        <section className="ErrorPage">
          <div className="wrapper">
            <div className="error-page-content">
              {
                isRouteErrorResponse(error)
                  ? (
                    <>
                      <img
                        src={notFound}
                        alt="notFound"
                      />
                      <p className="primary-paragraph">
                        {t('not_found.primary_message')}
                      </p>
                      <p className="secondary-paragraph">
                        {t('not_found.secondary_message')}
                      </p>
                    </>
                  )
                  : (
                    <>
                      <p className="primary-paragraph">
                        {t('unexpected_error.primary_message')}
                      </p>
                      <p className="secondary-paragraph">
                        {t('unexpected_error.secondary_message')}
                      </p>
                    </>
                  )
              }
              <Button
                priority="primary"
                link={
                  i18n.languages[0] === 'en'
                    ? '/en/'
                    : '/'
                }
              >
                {t('buttons:go_to_main_page')}
              </Button>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
