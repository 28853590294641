/* eslint-disable default-param-last */
import {
  SHOW_HAMBURGER_MENU,
  HIDE_HAMBURGER_MENU,
  SHOW_MAIN_DROPDOWN_LIST,
  HIDE_MAIN_DROPDOWN_LIST,
  SHOW_DOCUMENTS_DROPDOWN_LIST,
  HIDE_DOCUMENTS_DROPDOWN_LIST,
  SHOW_PROPOSALS_FOR_CCI_DROPDOWN_LIST,
  HIDE_PROPOSALS_FOR_CCI_DROPDOWN_LIST,
  SHOW_SEARCH_FORM,
  HIDE_SEARCH_FORM,
  SHOW_LANGUAGE_DROPDOWN_LIST,
  HIDE_LANGUAGE_DROPDOWN_LIST,
} from '../actionTypes';

const initialState = {
  isHamburgerMenuActive: false,
  isMainDropdownListVisible: false,
  isDocumentsDropdownListVisible: false,
  isProposalsForCCIDropdownListVisible: false,
  isSearchFormActive: false,
  isLanguageDropdownListVisible: false,
};

const menusReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_HAMBURGER_MENU:
      return {
        ...state,
        isHamburgerMenuActive: true,
      };
    case HIDE_HAMBURGER_MENU:
      return {
        ...state,
        isHamburgerMenuActive: false,
      };
    case SHOW_MAIN_DROPDOWN_LIST:
      return {
        ...state,
        isMainDropdownListVisible: true,
      };
    case HIDE_MAIN_DROPDOWN_LIST:
      return {
        ...state,
        isMainDropdownListVisible: false,
      };
    case SHOW_DOCUMENTS_DROPDOWN_LIST:
      return {
        ...state,
        isDocumentsDropdownListVisible: true,
      };
    case HIDE_DOCUMENTS_DROPDOWN_LIST:
      return {
        ...state,
        isDocumentsDropdownListVisible: false,
      };
    case SHOW_PROPOSALS_FOR_CCI_DROPDOWN_LIST:
      return {
        ...state,
        isProposalsForCCIDropdownListVisible: true,
      };
    case HIDE_PROPOSALS_FOR_CCI_DROPDOWN_LIST:
      return {
        ...state,
        isProposalsForCCIDropdownListVisible: false,
      };
    case SHOW_SEARCH_FORM:
      return {
        ...state,
        isSearchFormActive: true,
      };
    case HIDE_SEARCH_FORM:
      return {
        ...state,
        isSearchFormActive: false,
      };
    case SHOW_LANGUAGE_DROPDOWN_LIST:
      return {
        ...state,
        isLanguageDropdownListVisible: true,
      };
    case HIDE_LANGUAGE_DROPDOWN_LIST:
      return {
        ...state,
        isLanguageDropdownListVisible: false,
      };
    default:
      return state;
  }
};

export default menusReducer;
