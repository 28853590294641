/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import './Button.scss';

export default function Button({
  priority,
  link,
  target,
  download,
  children,
  ...props
}) {
  return (
    <button
      className={`button button_${priority}`}
      type="button"
    >
      {
        target || download
          ? (
            <a
              href={link}
              target={target}
              download={download}
              rel="noreferrer"
            >
              {children}
            </a>
          )
          : (
            <Link
              to={link}
              {...props}
            >
              {children}
            </Link>
          )
      }
    </button>
  );
}
