/* eslint-disable default-param-last */
import {
  EVENTS_SUCCESS,
  EVENTS_FAILURE,
  EVENTS_PER_PAGE,
  EVENTS_PAGE_COUNT,
  EVENTS_CURRENT_PAGE,
} from '../actionTypes';

const initialState = {
  eventList: {},
  perPage: null,
  pageCount: null,
  currentPage: null,
  isReady: false,
  notification: 'event_list_loading',
};

const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case EVENTS_SUCCESS:
      return {
        ...state,
        eventList: action.eventList,
        isReady: true,
        notification: '',
      };
    case EVENTS_FAILURE:
      return {
        ...state,
        eventList: {},
        perPage: null,
        pageCount: null,
        currentPage: null,
        isReady: false,
        notification: action.notification,
      };
    case EVENTS_PER_PAGE:
      return {
        ...state,
        perPage: action.perPage,
      };
    case EVENTS_PAGE_COUNT:
      return {
        ...state,
        pageCount: action.pageCount,
      };
    case EVENTS_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage,
      };
    default:
      return state;
  }
};

export default eventsReducer;
