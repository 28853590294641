import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { sendInitiativeForm } from '../../requests/requests';
import { showInfoMessage, showUploadFiles } from '../../store/actions/modalWindowsActions';
import OrdinaryInput from '../ReusableElements/FormElemets/OrdinaryInput/OrdinaryInput';
import PhoneInputElement from '../ReusableElements/FormElemets/PhoneInputElement/PhoneInputElement';
import Textarea from '../ReusableElements/FormElemets/Textarea/Textarea';
import attach from '../../assets/icons/actions/attach.svg';
import Submit from '../ReusableElements/Buttons/Submit/Submit';
import InfoMessage from '../ModalWindows/InfoMessage/InfoMessage';
import UploadFiles from '../ModalWindows/UploadFiles/UploadFiles';
import './InitiativeForm.scss';

export default function InitiativeForm() {
  const { t } = useTranslation(['form_error_messages', 'form_fields', 'personal_account', 'buttons', 'info_messages']);
  const [minPhoneLengthLimit, setMinPhoneLengthLimit] = useState();
  const [requiredPhoneLength, setRequiredPhoneLength] = useState();
  const [currentDialCode, setCurrentDialCode] = useState('380');
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('+380');
  const dispatch = useDispatch();
  const {
    isInfoMessageVisible,
    contentKey,
  } = useSelector((state) => state.modalWindows.infoMessage);
  const { isUploadFilesVisible } = useSelector((state) => state.modalWindows);

  const initiativeSchema = Yup.object({
    initiative_title: Yup
      .string()
      .required(t('initiative_form.initiative_title.required'))
      .matches(/^["“”„“«».,:()'/!?№+=*@a-zа-щєіїьюя\d\s–-]+$/i, t('initiative_form.initiative_title.matches'))
      .min(2, t('initiative_form.initiative_title.min')),
    phone: Yup
      .string()
      .min(minPhoneLengthLimit + 2, t('initiative_form.phone.min'))
      .length(requiredPhoneLength + 1, t('initiative_form.phone.length')),
    email: Yup
      .string()
      .required(t('initiative_form.email.required'))
      .email(t('initiative_form.email.email')),
    initiative_content: Yup
      .string()
      .trim()
      .required(t('initiative_form.initiative_content.required')),
  });

  const sendInitiative = (values, actions) => {
    const formData = { ...values };

    formData.initiative_title = formData.initiative_title.trim();
    formData.email = formData.email.toLowerCase();
    formData.initiative_content = formData.initiative_content.trim();

    sendInitiativeForm(formData)
      .then((response) => {
        if (response.status === 200) {
          actions.resetForm({
            touched: {},
            submitCount: 0,
          });

          setCurrentDialCode('380');
          setFormattedPhoneNumber('+380');
          dispatch(showInfoMessage('initiative_form'));
        }

        console.log(response.data);
      })
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const setPhoneParameters = (minLengthLimit, requiredLength) => {
    setMinPhoneLengthLimit(minLengthLimit);
    setRequiredPhoneLength(requiredLength);
  };

  return (
    <>
      <Formik
        initialValues={{
          initiative_title: '',
          phone: '+380',
          email: '',
          initiative_content: '',
        }}
        validationSchema={initiativeSchema}
        onSubmit={sendInitiative}
      >
        {(formik) => (
          <Form className="initiative-form">
            <div className="initiative-form-fields">
              <OrdinaryInput
                label={t('form_fields:initiative_form.initiative_title.label')}
                placeholder={t('form_fields:initiative_form.initiative_title.placeholder')}
                name="initiative_title"
                type="text"
                maxLength={40}
              />
              <PhoneInputElement
                label={t('form_fields:initiative_form.phone.label')}
                placeholder={t('form_fields:initiative_form.phone.placeholder')}
                name="phone"
                type="tel"
                setPhoneParameters={setPhoneParameters}
                currentDialCode={currentDialCode}
                setCurrentDialCode={setCurrentDialCode}
                formattedPhoneNumber={formattedPhoneNumber}
                setFormattedPhoneNumber={setFormattedPhoneNumber}
              />
              <OrdinaryInput
                label={t('form_fields:initiative_form.email.label')}
                placeholder={t('form_fields:initiative_form.email.placeholder')}
                name="email"
                type="email"
              />
              <Textarea
                label={t('form_fields:initiative_form.initiative_content.label')}
                placeholder={t('form_fields:initiative_form.initiative_content.placeholder')}
                name="initiative_content"
                rows={2}
                maxRows={7}
              />
            </div>
            <button
              className="attach"
              type="button"
              onClick={() => dispatch(showUploadFiles())}
            >
              <img
                src={attach}
                alt="attach"
              />
              <span>
                {t('personal_account:initiative.attach_files')}
              </span>
            </button>
            <Submit
              priority="primary"
              isDisabled={formik.isSubmitting}
            >
              {t('buttons:send')}
            </Submit>
          </Form>
        )}
      </Formik>
      {
        isInfoMessageVisible && contentKey === 'initiative_form' && (
          <InfoMessage>
            {t('info_messages:initiative_form')}
          </InfoMessage>
        )
      }
      {
        isUploadFilesVisible && <UploadFiles />
      }
    </>
  );
}
