/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import eye from '../../../assets/icons/info/eye.svg';
import calendarGreen from '../../../assets/icons/info/calendar_green.svg';
import './ArticleItem.scss';

export default function ArticleItem({ data }) {
  const { t } = useTranslation('landing_page', { keyPrefix: 'news_events' });

  return (
    <div className="article-list__item">
      <div className="article-list__cover">
        <img
          src={data.cover?.url}
          alt={data.title}
          loading="lazy"
        />
      </div>
      <div className="article-list__info">
        <h3 className="heading-three">
          {data.title}
        </h3>
        <div className="info-item">
          <img
            className="info-icon"
            src={eye}
            alt="eye"
          />
          <p className="paragraph">
            {`${t('info.views')}: ${data.viewed}`}
          </p>
        </div>
        <div className="info-item">
          <img
            className="info-icon"
            src={calendarGreen}
            alt="calendarGreen"
          />
          <p className="paragraph">
            {`${t('info.created_at')}: `}
            <Moment format="YYYY-MM-DD">
              {data.publication_date}
            </Moment>
          </p>
        </div>
      </div>
    </div>
  );
}
