/* eslint-disable default-param-last */
import { EVENT_SUCCESS, EVENT_FAILURE } from '../actionTypes';

const initialState = {
  data: {},
  isReady: false,
  notification: 'event_data_loading',
};

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case EVENT_SUCCESS:
      return {
        ...state,
        data: action.data,
        isReady: true,
        notification: '',
      };
    case EVENT_FAILURE:
      return {
        ...state,
        data: {},
        isReady: false,
        notification: action.notification,
      };
    default:
      return state;
  }
};

export default eventReducer;
