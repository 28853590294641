import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceList from '../ServiceList/ServiceList';
import './Services.scss';

export default function Services() {
  const { t } = useTranslation('landing_page', { keyPrefix: 'services' });

  useEffect(() => {
    const header = document.querySelector('.Header');
    const servicesAnchor = document.querySelector('#services');

    servicesAnchor.style.top = `-${header.offsetHeight}px`;
  }, []);

  return (
    <section className="Services">
      <div id="services" className="anchor" />
      <div className="wrapper">
        <div className="services-content">
          <h2 className="heading-two">
            {t('heading')}
          </h2>
          <ServiceList />
        </div>
      </div>
    </section>
  );
}
