/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
// import * as Yup from 'yup';
// import { Formik, Form } from 'formik';
import { hideUploadFiles } from '../../../store/actions/modalWindowsActions';
// import { sendHotlineForm } from '../../../requests/requests';
import closeWhite from '../../../assets/icons/actions/close_white.svg';
// import DatePickerElement from '../../ReusableElements/FormElemets/DatePickerElement/DatePickerElement';
// import PhoneInputElement from '../../ReusableElements/FormElemets/PhoneInputElement/PhoneInputElement';
// import Submit from '../../ReusableElements/Buttons/Submit/Submit';
import './UploadFiles.scss';

export default function Hotline() {
  const { t } = useTranslation(['form_error_messages', 'form_fields', 'buttons']);
  const dispatch = useDispatch();

  const closeModalWindow = () => {
    dispatch(hideUploadFiles());
  };

  useEffect(() => {
    const container = document.querySelector('.hotline .container');
    const modalWindow = document.querySelector('.hotline .modal-window');

    if (container.offsetHeight < modalWindow.offsetHeight + 12) {
      container.style.alignItems = 'flex-start';
      modalWindow.style.marginTop = '12px';
    } else {
      container.style.alignItems = '';
      modalWindow.style.marginTop = '';
    }
  }, []);

  return (
    <div className="upload-files">
      <div className="container">
        <div className="modal-window">
          <h1 className="heading-one">
            {t('form_fields:hotline_form.heading')}
          </h1>
          <button
            className="close"
            type="button"
            onClick={closeModalWindow}
          >
            <img
              src={closeWhite}
              alt="closeWhite"
            />
          </button>
        </div>
      </div>
    </div>
  );
}
