import { VIP_COMPANIES_SUCCESS, VIP_COMPANIES_FAILURE } from '../actionTypes';
import { getVIPCompanies } from '../../requests/requests';

const vipCompaniesRequestSucceeded = (vipCompanyData) => ({
  type: VIP_COMPANIES_SUCCESS,
  vipCompanyData,
});

const vipCompaniesRequestFailed = (notification) => ({
  type: VIP_COMPANIES_FAILURE,
  notification,
});

const vipCompaniesAPI = () => (dispatch) => {
  getVIPCompanies()
    .then((response) => {
      console.log(response.data);
      console.log(response.status);
      console.log(response.statusText);
      console.log(response.headers);
      console.log(response.config);
      console.log(response.request);

      dispatch(vipCompaniesRequestSucceeded(response.data));
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.statusText);
        console.log(error.response.headers);
        console.log(error.response.config);
        console.log(error.response.request);
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log(error.config);
      }
      console.log(error.message);

      dispatch(vipCompaniesRequestFailed(error.message));
    })
    .finally(() => {});
};

export default vipCompaniesAPI;
