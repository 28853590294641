/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { searchResultsAPI } from '../../store/actions/searchResultsActions';
import SearchResultsList from '../SearchResultsList/SearchResultsList';
import './SearchResultsPage.scss';

export default function SearchResultsPage() {
  const { t, i18n } = useTranslation(['search_results_page', 'notifications']);
  const [minimumPageRequiredHeight, setMinimumPageRequiredHeight] = useState();
  const dispatch = useDispatch();
  const {
    searchQuery,
    searchResultsList,
    perPage,
    pageCount,
    currentPage,
    isReady,
    notification,
  } = useSelector((state) => state.searchResults);

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'instant',
    });

    if (searchQuery) {
      dispatch(searchResultsAPI());
    }
  }, [currentPage]);

  useEffect(() => {
    const header = document.querySelector('.Header.fixed');
    const searchResultsPage = document.querySelector('.SearchResultsPage');
    const footer = document.querySelector('.Footer');
    const minimumRequiredHeight = document.documentElement.clientHeight - (header.offsetHeight + footer.offsetHeight);

    searchResultsPage.style.minHeight = `${minimumRequiredHeight}px`;
    setMinimumPageRequiredHeight(minimumRequiredHeight);
  }, []);

  return (
    <section className="SearchResultsPage">
      <div className="wrapper">
        <div className="search-results-page-content">
          <a
            className="previous-page-link"
            href={
              i18n.languages[0] === 'en'
                ? '/en/'
                : '/'
            }
          >
            {t('previous_page_title')}
          </a>
          <h1 className="heading-one">
            {t('heading')}
          </h1>
          {
            searchQuery && perPage && pageCount !== null && currentPage && isReady
              ? (
                <SearchResultsList
                  searchResultsList={searchResultsList}
                  pageCount={pageCount}
                  currentPage={currentPage}
                  minimumPageRequiredHeight={minimumPageRequiredHeight}
                />
              )
              : (
                <p className="notification">
                  {
                    searchQuery
                      ? t(`notifications:${notification}`, notification)
                      : t('notifications:search_query_is_missing')
                  }
                </p>
              )
          }
        </div>
      </div>
    </section>
  );
}
