/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './CCIDocuments.scss';

export default function CCIDocuments() {
  const { t } = useTranslation('personal_account', { keyPrefix: 'cci_documents' });

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'instant',
    });
  }, []);

  useEffect(() => {
    const header = document.querySelector('.Header.fixed');
    const CCIDocumentsContainer = document.querySelector('.CCIDocuments');
    const footer = document.querySelector('.Footer');
    const minimumRequiredHeight = document.documentElement.clientHeight - (header.offsetHeight + footer.offsetHeight);

    CCIDocumentsContainer.style.minHeight = `${minimumRequiredHeight}px`;
  }, []);

  return (
    <section className="CCIDocuments">
      <div className="wrapper">
        <div className="cci-documents-content">
          <h1 className="heading-one">
            {t('heading')}
          </h1>
        </div>
      </div>
    </section>
  );
}
