/* eslint-disable default-param-last */
import { ARTICLE_SUCCESS, ARTICLE_FAILURE } from '../actionTypes';

const initialState = {
  data: {},
  isReady: false,
  notification: 'article_data_loading',
};

const articleReducer = (state = initialState, action) => {
  switch (action.type) {
    case ARTICLE_SUCCESS:
      return {
        ...state,
        data: action.data,
        isReady: true,
        notification: '',
      };
    case ARTICLE_FAILURE:
      return {
        ...state,
        data: {},
        isReady: false,
        notification: action.notification,
      };
    default:
      return state;
  }
};

export default articleReducer;
