/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ProposalForm from '../../ProposalForm/ProposalForm';
import './Proposal.scss';

export default function Proposal() {
  const { t } = useTranslation('personal_account', { keyPrefix: 'proposal' });

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'instant',
    });
  }, []);

  useEffect(() => {
    const header = document.querySelector('.Header.fixed');
    const proposal = document.querySelector('.Proposal');
    const footer = document.querySelector('.Footer');
    const minimumRequiredHeight = document.documentElement.clientHeight - (header.offsetHeight + footer.offsetHeight);

    proposal.style.minHeight = `${minimumRequiredHeight}px`;
  }, []);

  return (
    <section className="Proposal">
      <div className="wrapper">
        <div className="proposal-content">
          <h1 className="heading-one">
            {t('heading')}
          </h1>
          <ProposalForm />
        </div>
      </div>
    </section>
  );
}
