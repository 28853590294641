/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Button from '../../ReusableElements/Buttons/Button/Button';
import CallOrderForm from '../../CallOrderForm/CallOrderForm';
import './ForceMajeureCertification.scss';

export default function ForceMajeureCertification() {
  const { t, i18n } = useTranslation(['service_pages', 'buttons']);

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'instant',
    });
  }, []);
  const ldJson = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Івано-Франківська торгово-промислова палата',
    url: 'https://cci.if.ua/',
    logo: 'https://cci.if.ua/static/media/logo_small.088e05e0446873d0c8dfb27546b82194.svg',
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: '+380342523347',
      contactType: 'customer service',
      areaServed: 'UA',
      availableLanguage: 'Ukrainian',
    },
    sameAs: [
      'https://www.facebook.com/cci.if.ua/',
      'https://www.instagram.com/if.cci.ua/',
    ],
  };
  return (
    <section className="ForceMajeureCertification">
      <Helmet>
        <title>
          Чи ви відповідаєте критеріям для сертифікації форс-мажору? | Івано-Франківська ТПП
        </title>
        <meta
          name="description"
          content="Дізнайтеся, чи маєте ви право на отримання сертифікату форс-мажору та як
          це може вплинути на Ваш бізнес від Івано-Франківської Торгово-промислової палати."
        />
        <script type="application/ld+json">
          {JSON.stringify(ldJson)}
        </script>
      </Helmet>
      <div className="wrapper">
        <div className="force-majeure-certification-content">
          <div className="force-majeure-certification-content-top-row">
            <a
              className="previous-page-link"
              href={
                i18n.languages[0] === 'en'
                  ? '/en/'
                  : '/'
              }
            >
              {t('previous_page_title')}
            </a>
            <div className="force-majeure-certification-content-top-row__right-side">
              <h1 className="heading-one">
                {t('heading.force_majeure_certification')}
              </h1>
              <p className="description">
                {t('description')}
              </p>
              <div className="introduction">
                <p className="paragraph">
                  Звільнення від мита, митних зборів і податків на тимчасове ввезення товарів протягом часу до одного
                  року на території країн-учасниць міжнародної системи ATA і є міжнародною чинною гарантією сплати
                  країні-учасниці ввізного мита і податків згідно її національного законодавства у разі порушення
                  режиму тимчасового ввезення. За детальнішою інформацією звертайтесь до нашої служби підтримки.
                </p>
              </div>
              <div className="buttons">
                <Button
                  priority="primary"
                  link="#"
                  download
                >
                  {t('buttons:download_the_form')}
                </Button>
                <Button
                  priority="secondary"
                  link="#"
                  target="_blank"
                >
                  {t('buttons:view_the_sample')}
                </Button>
              </div>
              <div className="explanation">
                <p className="paragraph">
                  Що таке форс-мажор чи обставини непереборної сили? В українському законодавстві цей термін визначено
                  як надзвичайні та невідворотні обставини, які об'єктивно унеможливлюють виконання зобов'язань,
                  передбачених умовами договору (контракту, угоди тощо) і обов'язків відповідно до законодавчих та
                  інших нормативних актів. Та які саме обставини можна вважати форс-мажорними, а які ні? В роботі з
                  нашими клієнтами ми часто зіштовхувалися з випадками неправильного трактування обставин непереборної
                  сили. Саме тому ми хочемо виокремити
                  {' '}
                  <span className="bold-font">
                    найважливіші пункти, які не є форс-мажором
                  </span>
                  :
                </p>
                <ul>
                  <li>
                    фінансова чи економічна криза;
                  </li>
                  <li>
                    зростання офіційного та комерційного курсів іноземної валюти до національної валюти;
                  </li>
                  <li>
                    дефолт;
                  </li>
                  <li>
                    відсутність на ринку необхідних для виконання зобов'язання товарів;
                  </li>
                  <li>
                    відсутність у боржника необхідних коштів.
                  </li>
                </ul>
                <p className="paragraph">
                  Підставою для засвідчення форс-мажору є наявність однієї аюо більше обставин. У світлі кризи,
                  спричиненої пандемією COVID-19, чимало підприємців стикнулися із неможливістю виконання своїх
                  договірних зобов'язань. Тому цей інструмент опинився «на піку популярності» в реаліях сьогодення.
                  Та мало хто знає, що після закінчення форс-мажорних обставин сторони все рівно зобов'язані виконати
                  умови договору. А сам сертифікат автоматично не звільняє суб'єктів господарювання від виконання своїх
                  обов'язків, а лише є доказом, яким заявник може скористатися для відтермінування виконання своїх
                  договірних зобов'язань або під час судового спору. У будь-якому випадку, якщо Ви не впевнені чи Ваша
                  ситуація є обставиною непереборної дії, ми будемо раді надати Вам безкоштовну консультацію.
                </p>
              </div>
            </div>
          </div>
          <h2 className="heading-two heading-two_form">
            {t('call_order_form_heading')}
          </h2>
          <p className="description">
            {t('call_order_form_description')}
          </p>
          <CallOrderForm />
        </div>
      </div>
    </section>
  );
}
