/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './ClientDocuments.scss';

export default function ClientDocuments() {
  const { t } = useTranslation('personal_account', { keyPrefix: 'client_documents' });

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'instant',
    });
  }, []);

  useEffect(() => {
    const header = document.querySelector('.Header.fixed');
    const clientDocuments = document.querySelector('.ClientDocuments');
    const footer = document.querySelector('.Footer');
    const minimumRequiredHeight = document.documentElement.clientHeight - (header.offsetHeight + footer.offsetHeight);

    clientDocuments.style.minHeight = `${minimumRequiredHeight}px`;
  }, []);

  return (
    <section className="ClientDocuments">
      <div className="wrapper">
        <div className="client-documents-content">
          <h1 className="heading-one">
            {t('heading')}
          </h1>
        </div>
      </div>
    </section>
  );
}
