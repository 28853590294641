/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import managersAPI from '../../store/actions/managersActions';
import ManagerList from '../LandingPage/ManagerList/ManagerList';
import './AboutUsPage.scss';

export default function AboutUsPage() {
  const { t, i18n } = useTranslation(['about_us_page', 'landing_page', 'notifications']);
  const dispatch = useDispatch();
  const {
    managerData,
    isReady,
    notification,
  } = useSelector((state) => state.managers);

  useEffect(() => {
    if (!isReady) {
      dispatch(managersAPI());
    }
  }, [isReady]);

  return (
    <section className="AboutUsPage">
      <div className="wrapper">
        <div className="about-us-page-content">
          <div className="about-us-page-content-top-row">
            <a
              className="previous-page-link"
              href={
                i18n.languages[0] === 'en'
                  ? '/en/'
                  : '/'
              }
            >
              {t('previous_page_title')}
            </a>
            <div className="about-us-page-content-top-row__right-side">
              <h1 className="heading-one">
                {t('heading')}
              </h1>
              <div className="about-us-activity">
                <p className="paragraph">
                  Івано-Франківська торгово-промислова палата (ТПП) є недержавною самоврядною організацією, яка
                  об′єднує юридичних осіб та громадян України, які зареєстровані як підприємці, та їх об′єднання з
                  метою сприяння розвитку національної економіки, орієнтована на інтеграцію у світову економічну
                  систему, створення сприятливих умов для підприємницької діяльності. Івано-Франківська ТПП об’єднує
                  більш ніж 300 суб’єктів підприємницької діяльності, які є основними підприємствами регіону та
                  створюють основу валового регіонального продукту Івано-Франківської області.
                </p>
                <p className="paragraph">
                  Діяльність ТПП включає усі сегменти розвитку економіки, а саме:
                </p>
                <ul>
                  <li>
                    підтримка бізнесу (через надання бізнес-послуг);
                  </li>
                  <li>
                    тісна взаємодія з органами влади як на регіональному рівні (органи фіскальної служби, митниці,
                    центру зайнятості) так і на центральному рівні (Кабінет міністрів України);
                  </li>
                  <li>
                    підтримка населення шляхом проведення бізнес-орієнтованих навчань для здобуття нових навичок та
                    компетенцій.
                  </li>
                </ul>
                <p className="paragraph">
                  Місія Івано-Франківської ТПП полягає в підтримці та сприянні розвитку підприємницької діяльності,
                  створенні сприятливого бізнес-середовища та підвищенні конкурентоспроможності наших членів. Ми
                  працюємо на благо економічного зростання, розвитку торгівлі та промисловості, а також сприяємо
                  зміцненню бізнес-спільноти та забезпеченню її представництва на різних рівнях.
                  Основними завданнями Івано-Франківської ТПП є:
                </p>
                <ul>
                  <li>
                    сприяння розвитку малого та середнього підприємництва;
                  </li>
                  <li>
                    розвиток експортних можливостей бізнесу регіону через налагодження співпраці щодо
                    експортно-імпортної діяльності;
                  </li>
                  <li>
                    залучення міжнародних партнерів;
                  </li>
                  <li>
                    вивчення міжнародного досвіду;
                  </li>
                  <li>
                    навчання та практична реалізація навичок ведення бізнесу.
                  </li>
                </ul>
                <p className="paragraph">
                  Послуги, що надає Івано-Франківська ТПП:
                </p>
                <ul>
                  <li>
                    проведення експертиз;
                  </li>
                  <li>
                    видача цінових довідок;
                  </li>
                  <li>
                    сертифікація походження товарів, належності до власного виробництва;
                  </li>
                  <li>
                    видача карнетів ATA;
                  </li>
                  <li>
                    митне оформлення (декларування, брокерські послуги);
                  </li>
                  <li>
                    засвідчення обставин непереборної сили (форс-мажорних обставин) з подальшим оформленням
                    сертифікатів;
                  </li>
                  <li>
                    консультації по міжнародній діяльності;
                  </li>
                  <li>
                    патентно-ліцензійні послуги (торгова марка);
                  </li>
                  <li>
                    оформлення договорів та заявок на штрихове кодування товарів.
                  </li>
                </ul>
                <p className="paragraph">
                  Протягом своєї діяльності Івано-Франківська ТПП стала провідним та надійним партнером для
                  міжнародних організацій. Серед ключових партнерів нашої організації є: USAID, EUROCHAMBRES,
                  EU4BUSINESS, Канадсько-українська ТПП, GIZ та ПРООН.
                </p>
                <p className="paragraph">
                  Івано-Франківська ТПП має досвід реалізації національних та міжрегіональних проєктів в партнерстві з
                  регіональними ТПП України, урядовими структурами (Мінекономіки, Мінцифра, Міністерство соціальної
                  політики, Міністерство регіонального розвитку і інфраструктури, Міністерство Аграрної політики тощо),
                  з органами державної і регіональної виконавчої влади та їх територіальними управліннями та органами
                  місцевого самоврядування. На базі Івано-Франківської ТПП створена Івано-Франківська жіноча
                  бізнес-палата, що об′єднує жіноче підприємництво області і міста та активно проводить ділові
                  бізнес-зустрічі та нетворкінги, жіночі бізнес-форуми, консультації з ведення бізнесу
                  жінкам-підприємцям. Також Івано-Франківська ТПП виступає одним із засновників Громадської спілки
                  «Агенція розвитку та підтримки бізнесу», основною метою діяльності якої є забезпечення стійкого
                  розвитку та підтримки підприємницького середовища, сприяючи ефективному функціонуванню та зростанню
                  підприємств.
                </p>
              </div>
            </div>
          </div>
          <h2 className="heading-two">
            {t('landing_page:management.heading')}
          </h2>
          <p className="description">
            {t('landing_page:management.description')}
          </p>
          {
            isReady
              ? <ManagerList managerData={managerData} />
              : (
                <p className="notification">
                  {t(`notifications:${notification}`, notification)}
                </p>
              )
          }
        </div>
      </div>
    </section>
  );
}
