/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import { useField, ErrorMessage } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import TextareaAutosize from 'react-autosize-textarea';
import './Textarea.scss';

export default function Textarea({
  label,
  name,
  rows,
  maxRows,
  ...props
}) {
  const [field, meta] = useField(name);

  const htmlFor = `${name}-${uuidv4()}`;
  const className = classNames({ invalid: meta.touched && meta.error });

  const changeHandler = (e) => {
    field.onChange(e);
  };

  return (
    <div className="textarea-wrapper">
      <label htmlFor={htmlFor}>
        {label}
      </label>
      <TextareaAutosize
        id={htmlFor}
        className={className}
        rows={rows}
        maxRows={maxRows}
        autoComplete="off"
        style={{
          resize: 'none',
          width: '100%',
        }}
        {...field}
        {...props}
        onChange={changeHandler}
      />
      <ErrorMessage
        className="warning"
        component="span"
        name={name}
      />
    </div>
  );
}
