import firstService from '../assets/icons/services/customs-and-brokerage-services.svg';
import secondService from '../assets/icons/services/expertise.svg';
import thirdService from '../assets/icons/services/international-cooperation.svg';
import fourthService from '../assets/icons/services/force-majeure-certification.svg';
import fifthService from '../assets/icons/services/certificates_barcodes_ATA-carnets.svg';
import sixthService from '../assets/icons/services/arbitration-court.svg';
import seventhService from '../assets/icons/services/conference-services.svg';

const serviceItems = [
  {
    id: 'customs_and_brokerage_services',
    titleKey: 'first_service',
    iconUrl: firstService,
  },
  {
    id: 'expertise',
    titleKey: 'second_service',
    iconUrl: secondService,
  },
  {
    id: 'international_cooperation',
    titleKey: 'third_service',
    iconUrl: thirdService,
  },
  {
    id: 'force_majeure_certification',
    titleKey: 'fourth_service',
    iconUrl: fourthService,
  },
  {
    id: 'certificates_barcodes_ATA_carnets',
    titleKey: 'fifth_service',
    iconUrl: fifthService,
  },
  {
    id: 'arbitration_court',
    titleKey: 'sixth_service',
    iconUrl: sixthService,
  },
  {
    id: 'conference_services',
    titleKey: 'seventh_service',
    iconUrl: seventhService,
  },
];

export default serviceItems;
