/* eslint-disable default-param-last */
import {
  SHOW_HOTLINE,
  HIDE_HOTLINE,
  SHOW_INFO_MESSAGE,
  HIDE_INFO_MESSAGE,
  SHOW_REGISTRATION,
  HIDE_REGISTRATION,
  SHOW_LOG_IN,
  HIDE_LOG_IN,
  SHOW_UPLOAD_FILES,
  HIDE_UPLOAD_FILES,
} from '../actionTypes';

const initialState = {
  isHotlineVisible: false,
  infoMessage: {
    isInfoMessageVisible: false,
    contentKey: null,
  },
  registration: {
    isRegistrationVisible: false,
    resetPasswordToken: null,
    email: null,
  },
  isLogInVisible: false,
  isUploadFilesVisible: false,
};

const modalWindowsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_HOTLINE:
      return {
        ...state,
        isHotlineVisible: true,
      };
    case HIDE_HOTLINE:
      return {
        ...state,
        isHotlineVisible: false,
      };
    case SHOW_INFO_MESSAGE:
      return {
        ...state,
        infoMessage: {
          isInfoMessageVisible: true,
          contentKey: action.contentKey,
        },
      };
    case HIDE_INFO_MESSAGE:
      return {
        ...state,
        infoMessage: {
          isInfoMessageVisible: false,
          contentKey: null,
        },
      };
    case SHOW_REGISTRATION:
      return {
        ...state,
        registration: {
          isRegistrationVisible: true,
          resetPasswordToken: action.resetPasswordToken,
          email: action.email,
        },
      };
    case HIDE_REGISTRATION:
      return {
        ...state,
        registration: {
          isRegistrationVisible: false,
          resetPasswordToken: null,
          email: null,
        },
      };
    case SHOW_LOG_IN:
      return {
        ...state,
        isLogInVisible: true,
      };
    case HIDE_LOG_IN:
      return {
        ...state,
        isLogInVisible: false,
      };
    case SHOW_UPLOAD_FILES:
      return {
        ...state,
        isUploadFilesVisible: true,
      };
    case HIDE_UPLOAD_FILES:
      return {
        ...state,
        isUploadFilesVisible: false,
      };
    default:
      return state;
  }
};

export default modalWindowsReducer;
