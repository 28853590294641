import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import serviceItems from '../../../constants/serviceItems';
import ServiceItem from '../ServiceItem/ServiceItem';
import './ServiceList.scss';

export default function ServiceList() {
  return (
    <div className="service-list">
      {
        serviceItems.map((serviceItem) => (
          <ServiceItem
            key={uuidv4()}
            data={serviceItem}
          />
        ))
      }
    </div>
  );
}
