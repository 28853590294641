/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { setSearchResultsCurrentPage } from '../../store/actions/searchResultsActions';
import ArticleItem from '../LandingPage/ArticleItem/ArticleItem';
import ListLoader from '../ReusableElements/ListLoader/ListLoader';
import Paginator from '../ReusableElements/Paginator/Paginator';
import './SearchResultsList.scss';

export default function SearchResultsList({
  searchResultsList,
  pageCount,
  currentPage,
  minimumPageRequiredHeight,
}) {
  const { t, i18n } = useTranslation('not_found_messages');
  const dispatch = useDispatch();

  const paginatorHelper = (e) => {
    dispatch(setSearchResultsCurrentPage(e.selected + 1));
  };

  useEffect(() => {
    if (currentPage > 1) {
      const searchResultsPageContent = document.querySelector('.search-results-page-content');
      const previousPageLink = document.querySelector('.previous-page-link');
      const headingOne = document.querySelector('.heading-one');
      const searchResultsListContainer = document.querySelector('.search-results-list');
      const { paddingTop, paddingBottom } = getComputedStyle(searchResultsPageContent);
      const minimumListRequiredHeight = minimumPageRequiredHeight - (parseFloat(paddingTop) + parseFloat(paddingBottom) + previousPageLink.offsetHeight + headingOne.offsetHeight);

      searchResultsListContainer.style.minHeight = `${minimumListRequiredHeight}px`;
    }
  }, [currentPage]);

  return (
    <>
      <div className="search-results-list">
        {
          searchResultsList[currentPage] && searchResultsList[currentPage].map((articleItem) => (
            <Link
              key={uuidv4()}
              to={
                i18n.languages[0] === 'en'
                  ? `/search_results/${articleItem.id}/en/`
                  : `/search_results/${articleItem.id}/`
              }
            >
              <ArticleItem data={articleItem} />
            </Link>
          ))
        }
        {
          currentPage > 1 && !searchResultsList[currentPage] && <ListLoader />
        }
      </div>
      {
        pageCount > 1
          ? (
            <Paginator
              pageCount={pageCount}
              currentPage={currentPage}
              handlePageClick={paginatorHelper}
            />
          )
          : pageCount === 0 && (
            <p className="search-results-not-found">
              {t('search_results_not_found')}
            </p>
          )
      }
    </>
  );
}
