import {
  COMPANIES_SUCCESS,
  COMPANIES_FAILURE,
  COMPANIES_PER_PAGE,
  COMPANIES_PAGE_COUNT,
  COMPANIES_CURRENT_PAGE,
} from '../actionTypes';
import { getCompanyList } from '../../requests/requests';

const companiesRequestSucceeded = (companyList) => ({
  type: COMPANIES_SUCCESS,
  companyList,
});

const companiesRequestFailed = (notification) => ({
  type: COMPANIES_FAILURE,
  notification,
});

const setCompaniesPerPage = (perPage) => ({
  type: COMPANIES_PER_PAGE,
  perPage,
});

const setCompaniesPageCount = (pageCount) => ({
  type: COMPANIES_PAGE_COUNT,
  pageCount,
});

export const setCompaniesCurrentPage = (currentPage) => ({
  type: COMPANIES_CURRENT_PAGE,
  currentPage,
});

export const companiesAPI = () => (dispatch, getState) => {
  const {
    companyList,
    perPage,
    pageCount,
    currentPage,
    isReady,
  } = { ...getState().companies };

  if (!perPage && !pageCount && !currentPage && !isReady) {
    getCompanyList(6, 1)
      .then((response) => {
        dispatch(companiesRequestSucceeded({ 1: response.data.companies }));
        dispatch(setCompaniesPerPage(6));
        dispatch(setCompaniesPageCount(response.data.page_count));
        dispatch(setCompaniesCurrentPage(1));
      })
      .catch((error) => dispatch(companiesRequestFailed(error.message)))
      .finally(() => {});
  } else if (!companyList[currentPage]) {
    getCompanyList(perPage, currentPage)
      .then((response) => {
        const newState = {
          ...companyList,
          [`${currentPage}`]: response.data.companies,
        };

        dispatch(companiesRequestSucceeded(newState));
      })
      .catch((error) => dispatch(companiesRequestFailed(error.message)))
      .finally(() => {});
  }
};
