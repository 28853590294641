/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import './EventInfo.scss';

export default function EventInfo({
  id, title, description, eventDate,
  // id, title, description, coordinator, email, phone, eventDate,
}) {
  useEffect(() => {}, []);

  return (
    <tr>
      <td>{id}</td>
      <td>{title}</td>
      <td>{description}</td>
      {/* <td>{coordinator}</td>
      <td>{email}</td>
      <td>{phone}</td> */}
      <td>{eventDate}</td>
    </tr>
  );
}
