/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../ReusableElements/Buttons/Button/Button';
import './ServiceItem.scss';

export default function ServiceItem({ data }) {
  const { t, i18n } = useTranslation(['landing_page', 'buttons']);

  useEffect(() => {
    const availableWindowWidth = document.documentElement.clientWidth;
    const serviceListIcon = document.querySelector(`img[alt="${data.titleKey}"]`);

    if (availableWindowWidth <= 1600 && availableWindowWidth > 1440) {
      serviceListIcon.style.width = `${0.9 * serviceListIcon.offsetWidth}px`;
    } else if (availableWindowWidth <= 1440 && availableWindowWidth > 1280) {
      serviceListIcon.style.width = `${0.85 * serviceListIcon.offsetWidth}px`;
    } else if (availableWindowWidth <= 1280 && availableWindowWidth > 1024) {
      serviceListIcon.style.width = `${0.8 * serviceListIcon.offsetWidth}px`;
    } else if (availableWindowWidth <= 1024 && availableWindowWidth > 834) {
      serviceListIcon.style.width = `${0.7 * serviceListIcon.offsetWidth}px`;
    } else if (availableWindowWidth <= 834 && availableWindowWidth > 601) {
      serviceListIcon.style.width = `${0.65 * serviceListIcon.offsetWidth}px`;
    } else if (availableWindowWidth <= 601) {
      serviceListIcon.style.width = `${0.6 * serviceListIcon.offsetWidth}px`;
    }
  }, []);

  return (
    <div className="service-list__item">
      <div className="service-list__item-top-row">
        <img
          className="service-list__icon"
          src={data.iconUrl}
          alt={data.titleKey}
        />
        <p className="service-list__title">
          {t(`services.service_items.${data.titleKey}`)}
        </p>
      </div>
      <Button
        priority="secondary"
        link={
          i18n.languages[0] === 'en'
            ? `/services/${data.id}/en/`
            : `/services/${data.id}/`
        }
      >
        {t('buttons:learn_more')}
      </Button>
    </div>
  );
}
