const communityIndicators = [
  {
    titleKey: 'first_indicator',
    indicator: 287,
  },
  {
    titleKey: 'second_indicator',
    indicator: 165,
  },
  {
    titleKey: 'third_indicator',
    indicator: 49,
  },
];

export default communityIndicators;
